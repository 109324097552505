import * as API from './api';
import * as yup from 'yup';
import { reportException } from '../libs/errors';
import { API_NAME } from '../libs/constants';

export const createSchema = yup.object().shape({
  nickname: yup.string().required('Nickname is required').default(''),
  avatar: yup.string().required('Avatar is required').default('DefaultAvatar'),
  email: yup.string().when(['welcomeOptIn', 'notificationOptIn'], {
    is: (welcomeOptIn, notificationOptIn) => (welcomeOptIn || notificationOptIn === true),
    then: yup.string().email('Must be an email address').default('').required('Email is required to receive notifications'),
    otherwise: yup.string().email('Must be an email address').default('')
  }),
  welcomeOptIn: yup.boolean().default(true),
  notificationOptIn: yup.boolean().default(true),
  termsOptIn: yup.boolean().oneOf([true], 'Please accept the terms and conditions').default(false)
}).noUnknown();

export const updateSchema = yup.object().shape({
  nickname: yup.string().required('Nickname is required').default(''),
  avatar: yup.string().required('Avatar is required').default('DefaultAvatar'),
  maskedEmail: yup.string(), // We use this to decide if an email has previously been saved.
  email: yup.string().when(['maskedEmail', 'notificationOptIn'], {
    is: (maskedEmail, notificationOptIn) => (!maskedEmail && notificationOptIn),
    then: yup.string().email('Must be an email address').default('').required('Email is required to receive notifications'),
    otherwise: yup.string().email('Must be an email address').default('')
  }),

  notificationOptIn: yup.boolean()
});

//
// API actions
//

export function del (adviserId) {
  return API.del(API_NAME, `/advisers/${adviserId}`);
}

// TODO Check response codes and throw errors as neccessary
export function get (adviserId) {
  return API.get(API_NAME, `/advisers/${adviserId}`);
}

export function post (activationSecretId, values) {
  try {
    createSchema.validateSync(values, { stripUnknown: false });
  } catch (error) {
    reportException(error);
    return Promise.reject(error);
  }

  return API.post(API_NAME, `/advisers/`, { body: { ...values, activationSecretId } });
}

export function put (adviserId, nickname, avatar, email, notificationOptIn) {
  return API.put(API_NAME, `/advisers/${adviserId}`, { body: { nickname, avatar, email, notificationOptIn } });
}

//
// getters/setters
//
