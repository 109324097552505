import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Alert, Container, FormControl, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { SaveButton } from '../components/FormButtons';
import React, { useState } from 'react';
import * as Auth from '../libs/auth';
import { reportException } from '../libs/errors';
import { Link } from 'react-router-dom';
import { SUPPORT_EMAIL } from '../libs/constants';

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email('Must be an email address').required('Email is required').default('')
});

function ForgotPasswordForm ({ handleSubmit }) {
  let initialValues = forgotPasswordSchema.default();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={forgotPasswordSchema}>
      {({ submitting, dirty, values, errors, touched }) => (
        <Form>
          <Field name='email'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Email</FormLabel>
                <FormControl {...field} autoFocus type='email' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
                <FormText>Enter your email address to receive a password reset code.</FormText>
              </FormGroup>
            )}
          </Field>
          <SaveButton disabled={submitting || !dirty}
            isLoading={submitting}
            text='Submit'
            loadingText='Submitting in…' />
        </Form>
      )}
    </Formik>
  );
}

const forgotPasswordConfirmationSchema = yup.object().shape({
  email: yup.string().email('Must be an email address').required('Email is required').default(''),
  confirmationCode: yup.string().default('').required(''),
  password: yup.string()
    .required('Please Enter your password')
    .matches(Auth.PASSWORD_REGEX, Auth.passwordMessage).default(''),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match').default('')
});

function ForgotPasswordConfirmationForm ({ handleSubmit, email }) {
  let initialValues = forgotPasswordConfirmationSchema.default();
  initialValues.email = email;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={forgotPasswordConfirmationSchema}>
      {({ submitting, dirty, values, errors, touched }) => (
        <Form>
          <Field name='email'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Email</FormLabel>
                <FormControl {...field} autoFocus type='email' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <Field name='confirmationCode'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Confirmation Code</FormLabel>
                <FormControl {...field} autoFocus type='tel' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid' style={{ display: 'block' }}>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
                <FormText>Please check your email for the code.</FormText>
              </FormGroup>
            )}
          </Field>
          <Field name='password'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Password</FormLabel>
                <FormControl {...field} type='password' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <Field name='confirmPassword'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Confirm Password</FormLabel>
                <FormControl {...field} type='password' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <SaveButton disabled={submitting || !dirty}
            isLoading={submitting}
            text='Reset Password'
            loadingText='Resetting…' />
        </Form>
      )}
    </Formik>
  );
}

export default function ForgotPassword (props) {
  const [alert, setAlert] = useState(null);
  const [confirmationEmail, setConfirmationEmail] = useState(null);

  async function handleSubmit (values, actions) {
    try {
      await Auth.forgotPassword(values.email);

      actions.setSubmitting(false);
      setAlert(null);
      setConfirmationEmail(values.email);
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e);

      if (e instanceof Auth.UserDisabledAuthError) {
        setAlert(<>This user has been disabled.  Please contact {SUPPORT_EMAIL} for more information.</>);
      } else if (e instanceof Auth.UserNotConfirmedAuthError) {
        setAlert(<>Please <Link to={'/login'}>login</Link> to confirm your email address.</>);
      } else if (e instanceof Auth.UserNotFoundAuthError) {
        setAlert(<>This email is not registered.  Please <Link to={'/signup'}>signup</Link>.</>);
      } else {
        setAlert(e.message);
      }
    }
  }

  async function handleSubmitConfirmation (values, actions) {
    try {
      await Auth.forgotPasswordSubmit(values.email, values.confirmationCode, values.password);
      await Auth.signIn(values.email, values.password);
      actions.setSubmitting(false);

      props.userHasAuthenticated(true);
      props.history.push('/');
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e);

      if (e instanceof Auth.UserDisabledAuthError) {
        setAlert(<>This user has been disabled.  Please contact {SUPPORT_EMAIL} for more information.</>);
      } else if (e instanceof Auth.UserNotConfirmedAuthError) {
        setAlert(<>Please <Link to={'/login'}>login</Link> to confirm your email address.</>);
      } else if (e instanceof Auth.UserNotFoundAuthError) {
        setAlert(<>This email is not registered. Please <Link to={'/signup'}>signup</Link>.</>);
      } else if (e instanceof Auth.CodeMismatchAuthError) {
        setAlert(<>Invalid verification code provided, please try again.</>);
      } else {
        setAlert(e.message);
      }
    }
  }

  return (
    <Container className='Login'>
      {alert && <Alert variant={'danger'}>{alert}</Alert>}
      { confirmationEmail === null
        ? <ForgotPasswordForm handleSubmit={handleSubmit} />
        : <ForgotPasswordConfirmationForm handleSubmit={handleSubmitConfirmation} email={confirmationEmail} /> }
    </Container>
  );
}
