import produce from 'immer';
import * as AA from './apiActions';
import * as AR from './apiReducers';
import * as Advisership from '../models/advisership';
import * as C from './constants';
import { useResource } from './hooks';

//
// Hooks
//

export function useAdvisership (advisershipId) {
  return useResource(
    () => fetchIfNeeded(advisershipId),
    () => isFetchingSelector(advisershipId),
    () => isDeletedSelector(advisershipId),
    () => fetchErrorSelector(advisershipId),
    () => dataSelector(advisershipId)
  );
}

export function useAdvisershipsList (goalId) {
  return useResource(
    listIfNeeded,
    isListingSelector,
    () => (state) => false, // it's not possible for the advisership list to be deleted, so this is a selector that always returns false
    listErrorSelector,
    () => listAllDataSelector((a) => a.goalId === goalId)
  );
}

//
// Selectors
//

export function dataSelector (advisershipId) { return AR.dataSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function listDataSelector () { return AR.listDataSelector(C.RESOURCE_TYPE_ADVISERSHIP); }
export function listAllDataSelector (filter) { return AR.listAllDataSelector(C.RESOURCE_TYPE_ADVISERSHIP, filter); }

export function isCreatingSelector (advisershipId) { return AR.isCreatingSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function createErrorSelector (advisershipId) { return AR.createErrorSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function createdIdSelector (advisershipId) { return AR.createdResourceIdSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }

export function isDeletingSelector (advisershipId) { return AR.isDeletingSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function deleteErrorSelector (advisershipId) { return AR.deleteErrorSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function isDeletedSelector (advisershipId) { return AR.isDeletedSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }

export function didInvalidateSelector (advisershipId) { return AR.didInvalidateSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function didInvalidateListSelector () { return AR.didInvalidateListSelector(C.RESOURCE_TYPE_ADVISERSHIP); }

export function isFetchingSelector (advisershipId) { return AR.isFetchingSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function fetchErrorSelector (advisershipId) { return AR.fetchErrorSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }

export function isListingSelector () { return AR.isListingSelector(C.RESOURCE_TYPE_ADVISERSHIP); }
export function listErrorSelector () { return AR.listErrorSelector(C.RESOURCE_TYPE_ADVISERSHIP); }

export function isUpdatingSelector (advisershipId) { return AR.isUpdatingSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }
export function updateErrorSelector (advisershipId) { return AR.updateErrorSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId); }

//
// Actions
//

export function createIfPossible (tempAdvisershipId, values) {
  return AA.createIfPossible(C.RESOURCE_TYPE_ADVISERSHIP, tempAdvisershipId, () =>
    Advisership.post(values)
      .then(resource => resource.advisershipId)
  );
}

export function deleteIfPossible (advisershipId) {
  return AA.deleteIfPossible(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId, () => Advisership.del(advisershipId));
}

export function fetchIfNeeded (advisershipId) {
  return AA.fetchIfNeeded(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId, () => Advisership.get(advisershipId));
}

export function invalidate (advisershipId) {
  return AA.invalidate(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId);
}

export function invalidateList () {
  return AA.invalidateList(C.RESOURCE_TYPE_ADVISERSHIP);
}

export function listIfNeeded () {
  return AA.listIfNeeded(C.RESOURCE_TYPE_ADVISERSHIP, () =>
    Advisership.list().then(adviserships => adviserships.map(advisership => ({ resourceId: advisership.advisershipId, resource: advisership })))
  );
}

export function updateDetails (advisershipId, nickname) {
  return (dispatch, getState) => {
    let advisership = AR.dataSelector(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId)(getState());
    let nextAdvisership = produce(advisership, draft => {
      Advisership.updateFields(draft, nickname);
    });
    return dispatch(AA.putResource(C.RESOURCE_TYPE_ADVISERSHIP, advisershipId, () => Advisership.put(nextAdvisership), () => Advisership.get(advisershipId)));
  };
}
