import { useMediaQuery } from 'react-responsive';

export function useXsBreakpoint () {
  return useMediaQuery({ maxWidth: 576 });
}
export function useSmBreakpoint () {
  return useMediaQuery({ minWidth: 576 });
}

export function useMdBreakpoint () {
  return useMediaQuery({ minWidth: 768 });
}

export function useLgBreakpoint () {
  return useMediaQuery({ minWidth: 992 });
}

export function useXlBreakpoint () {
  return useMediaQuery({ minWidth: 1200 });
}
