import { useDispatch, useSelector } from 'react-redux';

export const NO_DATA_CAUSE_DELETED = 'deleted';
export const NO_DATA_CAUSE_ERROR = 'error';
export const NO_DATA_CAUSE_LOADING = 'loading';
export const NO_DATA_CAUSE_UNKNOWN = 'unknown';

export function useResource (requestAction, isLoadingSelector, isDeletedSelector, errorSelector, dataSelector) {
  const dispatch = useDispatch();
  dispatch(requestAction());

  const isLoading = useSelector(isLoadingSelector());
  const isDeleted = useSelector(isDeletedSelector());
  const error = useSelector(errorSelector());
  const data = useSelector(dataSelector());

  let noData = null;

  if (error) {
    noData = { cause: NO_DATA_CAUSE_ERROR, error: error };
  } else if (!data) {
    if (isLoading) {
      noData = { cause: NO_DATA_CAUSE_LOADING };
    } else if (isDeleted) {
      noData = { cause: NO_DATA_CAUSE_DELETED };
    } else {
      noData = { cause: NO_DATA_CAUSE_UNKNOWN };
    }
  }

  return [data, noData];
}
