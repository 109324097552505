import React from 'react';
import { useAdviser } from '../redux/advisersActions';
import { Container } from 'react-bootstrap';
import GoalUpdatesTimeline from '../components/GoalUpdatesTimeline';
import { TokenAdviserPageHeader } from '../components/PageHeaders';
import { TokenAdviserNoData } from '../components/NoData';

export default function TokenAdviserShow (props) {
  const adviserId = props.match.params.id;

  const [adviser, noAdviser] = useAdviser(adviserId);
  if (noAdviser) { return <TokenAdviserNoData noData={noAdviser} />; }

  const goal = adviser.goal;

  return (
    <Container className='Updates' >
      <TokenAdviserPageHeader adviser={adviser} />
      <GoalUpdatesTimeline goalId={goal.goalId} updates={goal.updates} readOnly />
    </Container>
  );
}
