import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AA from '../redux/advisersActions';
import { Container } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { CancelButton, DeleteButton, SaveButton } from '../components/FormButtons';
import ErrorAlert from '../components/ErrorAlert';
import * as Adviser from '../models/adviser';
import TokenAdviserEditFields from '../components/TokenAdviserEditFields';
import { TokenAdviserPageHeader } from '../components/PageHeaders';
import { TokenAdviserNoData } from '../components/NoData';
import { reportException } from '../libs/errors';
import FormCheckbox from '../components/FormCheckbox';

export default function TokenAdviserInfoEdit (props) {
  const adviserId = props.match.params.id;

  const dispatch = useDispatch();

  const [adviser, noAdviser] = AA.useAdviser(adviserId);

  const isUpdating = useSelector(AA.isUpdatingSelector(adviserId));
  const isDeleting = useSelector(AA.isDeletingSelector(adviserId));

  const updateError = useSelector(AA.updateErrorSelector(adviserId));
  const deleteError = useSelector(AA.deleteErrorSelector(adviserId));

  if (noAdviser) { return <TokenAdviserNoData noData={noAdviser} />; }

  function handleSubmit (values, actions) {
    dispatch(AA.updateDetails(adviserId, values.nickname, values.avatar, values.email, values.notificationOptIn)).then(() => {
      actions.setSubmitting(false);
      props.history.push(`/advisers/${adviserId}/info`);
    }).catch((e) => {
      reportException(e);
      // ignore
    });
  }

  async function handleDelete (event) {
    event.preventDefault();

    const confirmed = window.confirm(
      'Are you sure you want to stop being an adviser for this goal?'
    );
    if (confirmed) {
      dispatch(AA.deleteIfPossible(adviserId)).catch((e) => {
        reportException(e);
        // ignore
      });
    }
  }

  let initialValues = {
    email: '',
    maskedEmail: adviser.maskedEmail,
    nickname: adviser.nickname,
    avatar: adviser.avatar,
    notificationOptIn: adviser.notificationOptIn
  };

  return (
    <Container>
      <TokenAdviserPageHeader adviser={adviser} />
      <ErrorAlert error={updateError} />
      <ErrorAlert error={deleteError} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Adviser.updateSchema} >
        {({ submitting, dirty, values, errors, touched }) => (
          <Form>
            <TokenAdviserEditFields values={values} errors={errors} touched={touched} emailPlaceholder={adviser.maskedEmail} />
            <FormCheckbox name='notificationOptIn' label={`Send email notifications when the goal is updated.`} />
            <SaveButton disabled={submitting || !dirty || isUpdating} isLoading={submitting || isUpdating} />
            <CancelButton to={`/advisers/${adviserId}/info`} />
            <DeleteButton isLoading={isDeleting} onClick={handleDelete} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}
