import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker as DRPicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './DateRangePicker.css';
import { HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION } from 'react-dates/constants';
import * as moment from 'moment';
import { useXsBreakpoint } from '../libs/responsiveBreakpoints';
import { toISODate } from '../libs/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DateRangePicker ({ values, onChange, onBlur, invalid, rangeStartAt = null, rangeEndAt = null }) {
  const [focusedInput, setFocusedInput] = useState(null);

  function isOutsideRange (d) {
    let isoD = d.format('YYYY-MM-DD');
    if ((rangeStartAt && rangeStartAt > isoD) || (rangeEndAt && rangeEndAt < isoD)) {
      return true;
    } else {
      return false;
    }
  }

  const isXs = useXsBreakpoint();

  function handleDatesChange ({ startDate, endDate }) {
    let startAt = startDate && startDate.format('YYYY-MM-DD');
    let endAt = endDate && endDate.format('YYYY-MM-DD');
    onChange(startAt, endAt === startAt ? null : endAt);
  }

  return (
    <div className={invalid ? 'invalidDate' : ''}>
      <DRPicker
        isDayHighlighted={(d) => (d.format('YYYY-MM-DD') === toISODate(new Date()))}
        orientation={isXs ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
        withFullScreenPortal={isXs}
        keepOpenOnDateSelect={false}
        minimumNights={0}

        block
        showClearDates
        startDate={values.startAt && moment(values.startAt)}
        startDateId='Start'
        endDate={values.endAt && moment(values.endAt)}
        endDateId='End'
        isOutsideRange={isOutsideRange}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => { focusedInput && onBlur(); setFocusedInput(focusedInput); document.activeElement.blur(); }}

        customCloseIcon={<FontAwesomeIcon icon='times' className='text-secondary' />}
      />
    </div>
  );
}
