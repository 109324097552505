import * as API from './api';
import * as yup from 'yup';
import { toISODatetime } from '../libs/dates';
import * as shortid from 'shortid';
import { reportException } from '../libs/errors';
import { API_NAME } from '../libs/constants';

export const createSchema = yup.object().shape({
  startAt: yup.string().matches(/\d\d\d\d-\d\d-\d\d/).typeError('Start date is required').required('Start date is required'),
  endAt: yup.string().matches(/\d\d\d\d-\d\d-\d\d/).nullable(),
  nickname: yup.string().required('Nickname is required').default(''),
  title: yup.string().required('Title is required').default(''),
  kpis: yup.array().of(
    yup.object({
      id: yup.string().required(),
      name: yup.string().required('KPI is required'),
      datapoints: yup.object().default({})
    })
  ).default([]).min(1, 'KPI is required').max(1),
  updates: yup.array().of(
    yup.object({
      id: yup.string().required(),
      title: yup.string().required('Update text is required'),
      text: yup.string().required('Update text is required'),
      createdAt: yup.string().matches(/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d\d\d)?Z/).typeError('Created at is required').required('Created at is required')
    })
  ).default([]).min(1, 'At least one update is required')
}).noUnknown();

export const schema = createSchema.concat(yup.object().shape({
  userId: yup.string().required().default('uid'),
  goalId: yup.string().required().default('uid'),
  createdAt: yup.number().positive().required()
}));

//
// API actions
//

export function del (goalId) {
  return API.del(API_NAME, `/goals/${goalId}`);
}

// TODO Check response codes and throw errors as neccessary
export function get (goalId) {
  return API.get(API_NAME, `/goals/${goalId}`);
}

export function list () {
  return API.get(API_NAME, '/goals');
}

export function post (values) {
  let validated = null;
  try {
    validated = createSchema.validateSync(build(values), { stripUnknown: false });
  } catch (error) {
    reportException(error);
    return Promise.reject(error);
  }

  return API.post(API_NAME, `/goals/`, { body: validated });
}

export function put (goal) {
  try {
    schema.validateSync(goal, { stripUnknown: false });
  } catch (error) {
    reportException(error);
    return Promise.reject(error);
  }

  return API.put(API_NAME, `/goals/${goal.goalId}`, { body: goal });
}

//
// getters/setters
//

export function build ({ nickname, title, startAt, endAt, kpis = [], updates = [] }) {
  let newGoal = createSchema.default();
  updateFields(newGoal, nickname, title, startAt, endAt);
  updateKpis(newGoal, kpis);
  updates.forEach((update) => updateUpdate(newGoal, update));
  return newGoal;
}

export function calcKpiDatapoints (kpi, isoDates, goalEndAt) {
  let datapoints = kpi.datapoints || {};
  return isoDates.map((d) => (d > goalEndAt ? undefined : datapoints[d] || 0));
}

export function findKpi (goal, kpiName) {
  return goal.kpis.find((el) => (el.name === kpiName));
}

export function updateFields (goal, nickname, title, startAt, endAt) {
  goal.nickname = nickname;
  goal.title = title;
  goal.startAt = startAt;
  goal.endAt = endAt;
}

export function updateKpis (goal, kpis) {
  goal.kpis = kpis; // TODO: need to think about being more intelligent here, reoder?  rename?
}

export function updateKpiDatapoints (goal, kpiName, isoDates, values) {
  let kpi = findKpi(goal, kpiName);
  kpi.datapoints || (kpi.datapoints = {});
  for (let i = 0; i < isoDates.length; i++) {
    kpi.datapoints[isoDates[i]] = values[i];
  }
}

export function newUpdate () {
  return {
    id: shortid.generate(),
    createdAt: toISODatetime(new Date()),
    text: '',
    title: ''
  };
}

export function updateUpdate (goal, updateInfo) {
  if (!goal.updates) {
    goal.updates = [];
  }
  const i = goal.updates.findIndex((u) => (u.id === updateInfo.id));
  if (i !== -1) {
    goal.updates[i] = updateInfo;
  } else {
    goal.updates.push(updateInfo);
  }
  goal.updates.sort((a, b) => {
    if ((a.createdAt || '') === (b.createdAt || '')) {
      return 0;
    } else if ((a.createdAt || '') > (b.createdAt || '')) {
      return -1;
    } else {
      return 1;
    }
  });
}

export function deleteUpdate (goal, updateId) {
  const i = goal.updates.findIndex((u) => (u.id === updateId));
  if (i !== -1) {
    goal.updates.splice(i, 1);
  }
}
