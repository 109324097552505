import React from 'react';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import './Home.css';
import { shortFormat } from '../libs/dates';
import { useDispatch } from 'react-redux';
import * as GA from '../redux/goalsActions';
import { GenericPageHeader } from '../components/PageHeaders';
import { SITE_NAME } from '../libs/constants';
import { GenericNoData } from '../components/NoData';

function Goals (props) {
  const dispatch = useDispatch();
  dispatch(GA.listIfNeeded());

  const [goals, noGoals] = GA.useGoalsList();

  if (noGoals) { return <GenericNoData noData={noGoals} />; }

  function renderGoal (goal) {
    return (
      <LinkContainer key={goal.goalId} to={`/goals/${goal.goalId}`} >
        <Container>
          <Row>
            <Col className='p-0'>
              <h4 className='mb-1 text-overflow-ellipsis'>{goal.title}</h4>
            </Col>
            <Col xs='auto' className='text-left'>
              <small className='text-muted'>{`${shortFormat(goal.startAt)} - ${(goal.endAt && shortFormat(goal.endAt)) || ''}`}</small>
            </Col>
          </Row>
        </Container>
      </LinkContainer>);
  }

  function renderGoalsList (goals) {
    let createItem = <LinkContainer key='new' to='/goals/new'><h4 className='m-0 text-secondary'><b>{'\uFF0B'}</b> Create a new goal</h4></LinkContainer>;
    let goalItems = goals.map(goal => renderGoal(goal));
    return [...goalItems, createItem].map((item, i) =>
      <ListGroupItem key={i} className='mb-2 rounded-3 shadow-sm'>{item}</ListGroupItem>
    );
  }

  return (
    <Container>
      <GenericPageHeader title={'My Goals'} />
      <ListGroup className='p-0'>
        {renderGoalsList(goals)}
      </ListGroup>
    </Container>
  );
}

export default function Home (props) {
  function renderLander () {
    return (
      <Container className='lander'>
        <h2>{SITE_NAME}</h2>
        <p>A simple goal tracking app</p>
        <div>
          <Link to='/login' className='btn btn-info btn-lg'>
            Login
          </Link>
          <Link to='/signup' className='btn btn-success btn-lg'>
            Signup
          </Link>
        </div>
      </Container>
    );
  }

  return (
    <div className='Home'>
      {props.isAuthenticated ? <Goals /> : renderLander()}
    </div>
  );
}
