import React, { useState } from 'react';
import {
  FormCheck,
  FormGroup,
  FormControl,
  FormLabel, Container, Alert
} from 'react-bootstrap';
import * as Auth from '../libs/auth';
import { SaveButton } from '../components/FormButtons';
import './Signup.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { reportException } from '../libs/errors';
import { SignupConfirmationForm } from '../components/SignupConfirmationForm';

export default function Signup (props) {
  const [newUser, setNewUser] = useState(null);
  const [alert, setAlert] = useState(null);

  async function handleSignupSubmit (values, actions) {
    try {
      await Auth.signUp(values.email, values.password);
      actions.setSubmitting(false);
      setNewUser({ email: values.email, password: values.password });
      setAlert(null);
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e);

      if (e instanceof Auth.UsernameExistsAuthError) {
        setAlert(<>This user is already registered.  Please <Link to={'/login'}>login</Link>.</>);
      } else {
        setAlert(e.message);
      }
    }
  }

  async function onConfirmationSuccess () {
    await Auth.signIn(newUser.email, newUser.password);

    props.userHasAuthenticated(true);
    props.history.push('/');
  }

  return (
    <Container className='Signup'>
      {alert && <Alert variant={'danger'}>{alert}</Alert>}
      {newUser === null
        ? <SignupForm handleSubmit={handleSignupSubmit} />
        : <SignupConfirmationForm email={newUser.email} onConfirmationSuccess={onConfirmationSuccess} setAlert={setAlert} />}
    </Container>
  );
}

const signupSchema = yup.object().shape({
  email: yup.string().email('Must be an email address').required('Email is required').default(''),
  password: yup.string()
    .required('Please Enter your password')
    .matches(Auth.PASSWORD_REGEX, Auth.passwordMessage).default(''),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Passwords must match').default(''),
  termsOptIn: yup.boolean().oneOf([true], 'Please accept the terms and conditions').default(false)
});

function SignupForm ({ handleSubmit }) {
  let initialValues = signupSchema.default();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={signupSchema}>
      {({ submitting, dirty, values, errors, touched }) => (
        <Form>
          <Field name='email'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Email</FormLabel>
                <FormControl {...field} autoFocus type='email' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <Field name='password'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Password</FormLabel>
                <FormControl {...field} type='password' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <Field name='confirmPassword'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Confirm Password</FormLabel>
                <FormControl {...field} type='password' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <Field name='termsOptIn'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormCheck>
                  <FormCheck.Input {...field} required isInvalid={!!(errors[field.name] && touched[field.name])} type='checkbox' />
                  <FormCheck.Label>I agree to the <Link to={'/terms'}>Terms of Service</Link>.</FormCheck.Label>
                  <FormControl.Feedback type='invalid'>
                    <ErrorMessage name={field.name} />
                  </FormControl.Feedback>
                </FormCheck>
              </FormGroup>
            )}
          </Field>
          <SaveButton disabled={submitting || !dirty}
            isLoading={submitting}
            text='Signup'
            loadingText='Signing up…' />
        </Form>
      )}
    </Formik>);
}
