import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as AAA from '../redux/adviserActivationsActions';
import * as AA from '../redux/advisersActions';
import { Container } from 'react-bootstrap';
import * as shortid from 'shortid';
import * as Adviser from '../models/adviser';
import { Redirect } from 'react-router';
import { Form, Formik } from 'formik';
import { CreateButton, DeleteButton } from '../components/FormButtons';
import TokenAdviserEditFields from '../components/TokenAdviserEditFields';
import ErrorAlert from '../components/ErrorAlert';
import { GenericPageHeader } from '../components/PageHeaders';
import { AdviserActivationNoData } from '../components/NoData';
import { Link } from 'react-router-dom';
import { reportException } from '../libs/errors';
import { SITE_NAME } from '../libs/constants';
import FormCheckbox from '../components/FormCheckbox';

export default function ActivateAdviser (props) {
  const activationSecretId = props.match.params.id;

  const [adviserActivation, noAdviserActivation] = AAA.useAdviserActivation(activationSecretId);

  const [tempId] = useState(shortid.generate());

  const dispatch = useDispatch();

  const isCreating = useSelector(AA.isCreatingSelector(tempId));
  const createError = useSelector(AA.createErrorSelector(tempId));
  const createdId = useSelector(AA.createdIdSelector(tempId));

  const isDeleting = useSelector(AAA.isDeletingSelector(activationSecretId));
  const deleteError = useSelector(AAA.deleteErrorSelector(activationSecretId));

  if (noAdviserActivation) { return <AdviserActivationNoData noData={noAdviserActivation} />; }

  function handleSubmit (values, actions) {
    const confirmed = values.welcomeOptIn ? true : window.confirm(
      'Make sure you bookmark the next page!  Since you opted out of emails it will be the only way to track the goal.'
    );

    if (confirmed) {
      dispatch(AA.createIfPossible(tempId, activationSecretId, values)).then(() => {
        actions.setSubmitting(false);
      }).catch((e) => {
        reportException(e);
        // ignore
      });
    } else {
      actions.setSubmitting(false);
    }
  }

  async function handleDelete (event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you don't want to be an adviser?"
    );
    if (confirmed) {
      dispatch(AAA.deleteIfPossible(activationSecretId)).catch((e) => {
        reportException(e);
        // ignore
      });
    }
  }

  if (createdId) {
    return (<Redirect to={`/advisers/${createdId}`} />);
  }

  let initialValues = Adviser.createSchema.default();
  initialValues.nickname = adviserActivation.nickname;

  return (
    <Container>
      <GenericPageHeader title={`Welcome to ${SITE_NAME}!`} />
      <ErrorAlert error={createError} />
      <ErrorAlert error={deleteError} />
      <p>You've been invited to help <strong>{adviserActivation.goal.nickname}</strong> <i>{adviserActivation.goal.title}</i>.</p>
      <p>Enter a few quick details about yourself and start tracking {adviserActivation.goal.nickname}'s progress.</p>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Adviser.createSchema} >
        {({ submitting, dirty, values, errors, touched }) => (
          <Form>
            <TokenAdviserEditFields values={values} errors={errors} touched={touched} />
            <FormCheckbox name='welcomeOptIn' label={`Send a welcome email with details on how to track the goal.`} />
            <FormCheckbox name='notificationOptIn' label={`Send email notifications when the goal is updated.`} />
            <FormCheckbox name='termsOptIn' required label={<>I agree to the <Link to={'/terms'}>Terms of Service</Link>.</>} />
            <CreateButton disabled={submitting || isCreating} isLoading={submitting || isCreating} />
            <DeleteButton isLoading={isDeleting} onClick={handleDelete} text='No Thanks' loadingText='Canceling…' />
          </Form>
        )}
      </Formik>
    </Container>
  );
}
