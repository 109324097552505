import React from 'react';
import { ErrorMessage, Field, getIn } from 'formik';
import { Col, Figure, FormCheck, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import * as AdvisershipDisplay from './AdvisershipDisplay';

export default function TokenAdviserEditFields ({ values, errors, touched, emailPlaceholder }) {
  return (
    <>
      <h4>Shared fields (visible to  you and your advisee)</h4>
      <Field name='nickname'>
        {({ field }) => (
          <FormGroup controlId={field.name}>
            <FormLabel>Nickname</FormLabel>
            <FormControl {...field} isInvalid={!!(errors[field.name] && touched[field.name])} type='text' />
            <FormControl.Feedback type='invalid'>
              <ErrorMessage name={field.name} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
      <Field name='avatar'>
        {({ field }) => (
          <FormGroup controlId={field.name}>
            <FormLabel>Avatar</FormLabel>
            <Row>
              {AdvisershipDisplay.avatarNames.map((key) => (
                <Col key={key} xs='4' md='2' className='text-center'><div className='mx-auto' style={{ maxWidth: '6em' }}>
                  <Figure className='w-100' >
                    <AdvisershipDisplay.Image advisership={{ ...values, avatar: key }} />
                    <Figure.Caption>
                      <FormCheck {...field} id={field.name + key} type='radio' value={key} checked={field.value === key} />
                    </Figure.Caption>
                  </Figure>
                </div></Col>
              ))}
            </Row>
            <FormControl.Feedback type='invalid'>
              <ErrorMessage name={field.name} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
      <h4>Private fields (visible only to you)</h4>
      <Field name='email'>
        {({ field }) => (
          <FormGroup controlId={field.name}>
            <FormLabel>Email</FormLabel>
            <FormControl {...field} isInvalid={!!(getIn(errors, field.name) && getIn(touched, field.name))} type='email' placeholder={emailPlaceholder} />
            <FormControl.Feedback type='invalid' style={{ display: 'block' }}>
              <ErrorMessage className='invalid-feedback' name={field.name} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
    </>
  );
}
