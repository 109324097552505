import * as moment from 'moment';

export function calcDateRange (startAt, endAt) {
  let d = fromISODate(startAt);
  let endDate = endAt || toISODate(new Date());
  let days = [];

  while (toISODate(d) <= endDate) { // eslint-disable-line no-unmodified-loop-condition
    days.push(toISODate(d));
    d.setDate(d.getDate() + 1);
  }
  return days;
}

export function calcWeekRange (startAt, endAt) {
  let d = new Date(startAt);
  let endDate = endAt ? new Date(endAt) : Date.now();
  let weeks = [];
  while (d < endDate) { // eslint-disable-line no-unmodified-loop-condition
    if (d.getDay() === 0) { // sunday
      weeks.push(new Date(d));
    }
    d.setDate(d.getDate() + 1);
  }
  return weeks;
}

export function toISODate (d) {
  if (moment.isMoment(d)) {
    return d.format('YYYY-MM-DD');
  } else {
    return d && `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`;
  }
}

const isoDateRe = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;
export function fromISODate (str) {
  if (typeof str === 'string') {
    let match = str.match(isoDateRe);
    if (match) {
      return new Date(match[1], match[2] - 1, match[3]);
    }
  }

  return '';
}

export function toISODatetime (d) {
  return d && moment(d).toISOString();
}

export function fromISODatetime (str) {
  return str && moment(str).toDate();
}

export function startOfWeek (date) {
  return new Date(date.setDate(date.getDate() - date.getDay()));
}

export function shortFormat (date) {
  let m = moment(date);
  if (m.isSame(moment(), 'year')) {
    return moment(date).format('MMM Do');
  } else {
    return moment(date).format('MMM Do YYYY');
  }
}
