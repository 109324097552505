import { FormCheck, FormControl, FormGroup } from 'react-bootstrap';
import { ErrorMessage, useField } from 'formik';
import React from 'react';

export default function FormCheckbox ({ name, required, label }) {
  const [field, { error, touched }] = useField({ name, type: 'checkbox' });
  return (
    <FormGroup controlId={field.name}>
      <FormCheck>
        <FormCheck.Input {...field} checked={field.checked} required={required} isInvalid={!!(error && touched)} type='checkbox' />
        <FormCheck.Label>{label}</FormCheck.Label>
        <FormControl.Feedback type='invalid'>
          <ErrorMessage name={field.name} />
        </FormControl.Feedback>
      </FormCheck>
    </FormGroup>
  );
}
