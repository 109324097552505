import React from 'react';
import { CancelButton, DeleteButton, SaveButton } from '../components/FormButtons';
import { Formik, Form } from 'formik';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as GA from '../redux/goalsActions';
import * as Goal from '../models/goal';
import GoalEditFields from '../components/GoalEditFields';
import ErrorAlert from '../components/ErrorAlert';
import PageTitle from '../components/PageTitle';
import { GenericNoData } from '../components/NoData';
import { reportException } from '../libs/errors';

export default function GoalEdit (props) {
  let goalId = props.match.params.id;

  const dispatch = useDispatch();

  const [goal, noGoal] = GA.useGoal(goalId);

  const isUpdating = useSelector(GA.isUpdatingSelector(goalId));
  const isDeleting = useSelector(GA.isDeletingSelector(goalId));

  const updateError = useSelector(GA.updateErrorSelector(goalId));
  const deleteError = useSelector(GA.deleteErrorSelector(goalId));

  if (noGoal) { return <GenericNoData noData={noGoal} />; }

  function handleSubmit (values, actions) {
    dispatch(GA.updateDetails(goalId, values)).then(() => {
      actions.setSubmitting(false);
      props.history.push(`/goals/${goalId}/info`);
    }).catch((e) => {
      reportException(e);
      // ignore
    });
  }

  async function handleDelete (event) {
    event.preventDefault();

    const confirmed = window.confirm(
      'Are you sure you want to delete this goal?'
    );
    if (confirmed) {
      dispatch(GA.deleteIfPossible(goalId)).then(() => {
        props.history.push(`/`);
      }).catch((e) => {
        reportException(e);
        // ignore
      });
    }
  }

  return (
    <Container>
      <PageTitle>Edit Goal</PageTitle>
      <ErrorAlert error={updateError} />
      <ErrorAlert error={deleteError} />
      <Formik
        initialValues={goal}
        onSubmit={handleSubmit}
        validationSchema={Goal.schema}
      >
        {({ submitting, dirty, values, errors, touched }) => (
          <Form>
            <GoalEditFields values={values} errors={errors} touched={touched} />
            <SaveButton disabled={submitting || !dirty || isUpdating} isLoading={submitting || isUpdating} />
            <CancelButton to={`/goals/${goalId}/info`} />
            <DeleteButton disabled={submitting || isUpdating || isDeleting} isLoading={isDeleting} onClick={handleDelete} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}
