import React, { useState } from 'react';
import { CancelButton, CreateButton } from '../components/FormButtons';
import GoalEditFields from '../components/GoalEditFields';
import { useDispatch, useSelector } from 'react-redux';
import * as GA from '../redux/goalsActions';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Goal from '../models/goal';
import { Container, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import * as shortid from 'shortid';
import { Redirect } from 'react-router';
import ErrorAlert from '../components/ErrorAlert';
import { GoalNewPageHeader } from '../components/PageHeaders';
import RichTextField from '../components/RichTextField';
import { reportException } from '../libs/errors';

export default function GoalNew () {
  const [tempGoalId] = useState(shortid.generate());

  const isCreating = useSelector(GA.isCreatingSelector(tempGoalId));
  const createError = useSelector(GA.createErrorSelector(tempGoalId));
  const createdGoalId = useSelector(GA.createdIdSelector(tempGoalId));

  const dispatch = useDispatch();

  function handleSubmit (values, actions) {
    dispatch(GA.createIfPossible(tempGoalId, values)).then(() => {
      actions.setSubmitting(false);
    }).catch((e) => {
      reportException(e);
      // ignore
    });
  }

  let initialValues = Goal.createSchema.default();
  initialValues.kpis.push({ name: '', id: shortid.generate() });
  initialValues.updates.push(Goal.newUpdate());

  if (createdGoalId) {
    return (<Redirect to={`/goals/${createdGoalId}`} />);
  } else {
    return (
      <Container>
        <GoalNewPageHeader />
        <ErrorAlert error={createError} />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={Goal.createSchema}>
          {({ submitting, dirty, values, errors, touched }) => (
            <Form>
              <GoalEditFields values={values} errors={errors} touched={touched} />
              <Field name='updates[0].text'>
                {({ field }) => (
                  <FormGroup controlId={field.name}>
                    <FormLabel>Initial Update <small className='text-muted'>explain your goal to your advisers</small></FormLabel>
                    <RichTextField value={field.value} titleName={'updates[0].title'} textName={'updates[0].text'} />
                    <FormControl.Feedback type='invalid' style={{ display: 'block' }}>
                      <ErrorMessage name={field.name} />
                    </FormControl.Feedback>
                  </FormGroup>
                )}
              </Field>
              <CreateButton disabled={submitting || !dirty || isCreating} isLoading={submitting || isCreating} />
              <CancelButton to={`/`} />
            </Form>
          )}
        </Formik>
      </Container>);
  }
}
