import React, { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import * as QRCode from 'qrcode.react';
import * as AdvisershipDisplay from '../components/AdvisershipDisplay';
import * as QueryString from 'query-string';
import { useAdvisership } from '../redux/advisershipsActions';
import { useGoal } from '../redux/goalsActions';
import { GoalAdviserPageHeader } from '../components/PageHeaders';
import { GenericNoData } from '../components/NoData';

function ActivationQRModal ({ advisership, onHide, ...props }) {
  const link = `${window.location.origin}/adviser_activations/${advisership.activationSecretId}`;

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={onHide}
    >
      <Modal.Header className={'border-0'} closeButton >
        <Modal.Title id='contained-modal-title-vcenter'>
          Scan with your camera to help me reach my goal!
        </Modal.Title>
      </Modal.Header >
      <Modal.Body className={'mx-auto mb-1'}>
        <a href={link}><QRCode renderAs={'svg'} size={208} value={link} /></a>
      </Modal.Body>
      <Modal.Footer className={'mx-auto border-0'}>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function ActivationLink ({ advisership, showQr }) {
  const [qrModalShow, setQrModalShow] = useState(showQr || false);

  if (advisership.adviserStatus !== 'pending') {
    return (<></>);
  }

  return (
    <>
      <Row>
        <Col>
          <p><small>Your adviser can scan the QR code with their phone's camera to complete the sign up.</small></p>
          <Button block variant='primary' onClick={() => setQrModalShow(true)}>
            Show Activation Code
          </Button>

          <ActivationQRModal {...{ advisership }} show={qrModalShow} onHide={() => setQrModalShow(false)} />
        </Col>
      </Row>
    </>
  );
}

export default function GoalAdviserShow (props) {
  let goalId = props.match.params.id;
  let advisershipId = props.match.params.advisershipId;

  const [goal, noGoal] = useGoal(goalId);
  const [advisership, noAdvisership] = useAdvisership(advisershipId);

  if (noGoal) { return <GenericNoData noData={noGoal} />; }
  if (noAdvisership) { return <GenericNoData noData={noAdvisership} />; }

  const queryParams = QueryString.parse(props.location.search);
  const showQr = queryParams.showQr === 'true';

  return (
    <Container>
      <GoalAdviserPageHeader goal={goal} title={advisership.nickname} editTo={`/goals/${goalId}/advisers/${advisershipId}/edit`} />
      <Row className='mb-2'>
        <Col style={{ maxWidth: '6em' }}>
          <AdvisershipDisplay.Image {...{ advisership }} />
        </Col>
        <Col className='d-flex align-items-end'>
          <h3>{advisership.nickname}</h3>
        </Col>
      </Row>
      <Row as='dl'>
        <Col as='dt' xs={12}>Status</Col>
        <Col as='dd' xs={12}>{advisership.adviserStatus}</Col>
        <Col as='dt' xs={12}>Last Notifcation Sent</Col>
        <Col as='dd' xs={12}>{advisership.lastNotificationSentAt ? new Date(advisership.lastNotificationSentAt).toLocaleDateString() : 'never'}</Col>
      </Row>
      <ActivationLink {...{ advisership, showQr }} />
    </Container>
  );
}
