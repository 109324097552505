import React, { useState } from 'react';
import KpiUpdateForm from './KpiUpdateForm';
import { Col, Row } from 'react-bootstrap';
import KpiChart, { calcDatapointEndAt, calcUpdateableDates } from './KpiChart';
import * as Goal from '../models/goal';

export default function Kpi ({ goalId, kpi, dates, goalStartAt, goalEndAt }) {
  const datapointEndAt = calcDatapointEndAt(goalEndAt);
  const datapoints = Goal.calcKpiDatapoints(kpi, dates, datapointEndAt);
  const updateableDates = calcUpdateableDates(dates, goalStartAt, datapointEndAt);

  const [updateNode, setUpdateNode] = useState(updateableDates.length - 1);

  return (
    <>
      <Row>
        <Col><h5>{kpi.name}</h5></Col>
      </Row>
      <Row>
        <Col xs={12}>
          <KpiUpdateForm {...{ goalId, kpiName: kpi.name, index: updateNode, dates: updateableDates, datapoints, onIndexChange: setUpdateNode }} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <KpiChart {...{ kpiName: kpi.name, dates, datapoints, setUpdateNode: (v) => { setUpdateNode(v); } }} />
        </Col>
      </Row>
    </>
  );
}
