const dev = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://osjf2c647f.execute-api.us-east-1.amazonaws.com/dev'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_gLfMxkwxE',
    APP_CLIENT_ID: '2en2d3uru4cssusj2lo6jekkmn',
    IDENTITY_POOL_ID: 'us-east-1:278a23d3-7cb8-4a5b-94c1-6b29424d164a'
  }
};

const prod = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://y3zn4sflsg.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_vmMo1PuW8',
    APP_CLIENT_ID: '1haqd3kp412dqhimoob035nc32',
    IDENTITY_POOL_ID: 'us-east-1:29d2e921-7f6b-4594-8dc2-d0449f0e58b1'
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 500000,
  ...config
};
