import React from 'react';

const avatarFunctions = {
  DefaultAvatar,
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4
};

export const avatarNames = Object.keys(avatarFunctions);

function DefaultAvatar ({ advisership }) {
  return (
    <svg className={`bd-placeholder-img rounded-circle border ${colors(advisership.adviserStatus).border}`} viewBox='0 0 75 75'
      xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid slice' focusable='false'
      role='img'
      aria-label={advisership.nickname}><title>{advisership.nickname}</title>
      <rect width='100%' height='100%' fill='#868e96' />
      <text textAnchor='middle' style={{ fontSize: '36px' }} x='50%' y='50%' fill='#dee2e6' dy='.4em'
        className='font-weight-bold'>{advisership.nickname && advisership.nickname.slice(0, 1)}</text>
    </svg>
  );
}

function Avatar1 ({ advisership }) {
  return (
    <svg className={`rounded-circle border ${colors(advisership.adviserStatus).border}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M20.822 19.307c-2.967-.681-6.578-2.437-5.514-4.723.684 1.126 2.801 1.777 4.45.804-4.747-1.204 2.334-9.471-3.871-14.105-1.135-.853-2.526-1.283-3.912-1.283-1.378 0-2.751.425-3.862 1.283-6.206 4.634.876 12.901-3.872 14.105 1.649.974 3.77.293 4.451-.804 1.064 2.286-2.551 4.043-5.514 4.723-2.978.682-3.178 2.466-3.178 4.004l.005.689h23.99l.005-.691c0-1.537-.2-3.32-3.178-4.002z' />
    </svg>
  );
}

function Avatar2 ({ advisership }) {
  return (
    <svg className={`rounded-circle border ${colors(advisership.adviserStatus).border}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z' />
    </svg>
  );
}

function Avatar3 ({ advisership }) {
  return (
    <svg className={`rounded-circle border ${colors(advisership.adviserStatus).border}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path
        d='M20.822 18.096c-3.439-.794-6.641-1.49-5.09-4.418 4.719-8.912 1.251-13.678-3.732-13.678-5.081 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-2.979.688-3.178 2.143-3.178 4.663l.005 1.241h10.483l.704-3h1.615l.704 3h10.483l.005-1.241c.001-2.52-.198-3.975-3.177-4.663zm-8.231 1.904h-1.164l-.91-2h2.994l-.92 2z' />
    </svg>
  );
}

function Avatar4 ({ advisership }) {
  return (
    <svg className={`rounded-circle border ${colors(advisership.adviserStatus).border}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <path d='M20.822 19.307c-2.967-.681-6.578-2.437-5.514-4.723.684 1.126 2.801 1.777 4.45.804-4.747-1.204 2.334-9.471-3.871-14.105-1.135-.853-2.526-1.283-3.912-1.283-1.378 0-2.751.425-3.862 1.283-6.206 4.634.876 12.901-3.872 14.105 1.649.974 3.77.293 4.451-.804 1.064 2.286-2.551 4.043-5.514 4.723-2.978.682-3.178 2.466-3.178 4.663l.005 1.241h10.483l.704-3h1.615l.704 3h10.483l.005-1.241c.001-2.52-.198-3.975-3.177-4.663zm-8.231 1.904h-1.164l-.91-2h2.994l-.92 2z' />
    </svg>
  );
}

export function colors (advisershipStatus) {
  switch (advisershipStatus) {
    case 'pending':
      return { border: 'border-warning', text: 'text-warning' };
    case 'active':
      return { border: 'border-success', text: 'text-success' };
    case 'deleted':
      return { border: 'border-danger', text: 'text-danger' };
    default:
      return { border: '', text: '' };
  }
}

export function Image ({ advisership }) {
  let avatarFunc = avatarFunctions[advisership.avatar] || DefaultAvatar;
  return (avatarFunc({ advisership }));
}

export function CreateImage () {
  return (
    <svg className='bd-placeholder-img rounded-circle' viewBox='0 0 75 75'
      xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid slice' focusable='false' role='img'
      aria-label='New Adviser'><title>New Adviser</title>
      <rect width='100%' height='100%' fill='#dee2e6' />
      <text textAnchor='middle' style={{ fontSize: '56px' }} x='50%' y='50%' fill='#868e96' dy='.4em' className='font-weight-bold'>+</text>
    </svg>
  );
}
