import * as C from './constants';
import { produce } from 'immer';
import { combineReducers } from 'redux';

//
// Data
//

// Selectors

export function dataSelector (resourceType, resourceId) {
  return (state) => (resourceIsLoaded(state, resourceType, resourceId) && state.resourceDataByType[resourceType].byId[resourceId]);
}

export function listDataSelector (resourceType) {
  return (state) => (resourceListIsLoaded(state, resourceType) && state.resourceDataByType[resourceType].list);
}

export function listAllDataSelector (resourceType, filter) {
  return (state) => {
    if (resourceListIsLoaded(state, resourceType)) {
      let resources = state.resourceDataByType[resourceType].list.map(id => state.resourceDataByType[resourceType].byId[id]);
      if (filter) {
        return resources.filter(filter);
      } else {
        return resources;
      }
    } else {
      return false;
    }
  };
}

// Reducers

function resourceData (
  state = {},
  action
) {
  switch (action.type) {
    case C.API__INVALIDATE:
      return {};
    case C.API__RECEIVE:
      return Object.assign({}, state, action.resource);
    default:
      return state;
  }
}

export function resourceDataById (state = {}, action) {
  switch (action.type) {
    case C.API__RECEIVE:
      return produce(state, draft => {
        draft[action.resourceId] = resourceData(draft[action.resourceId], action);
      });
    default:
      return state;
  }
}

export function resourceListData (state = [], action) {
  switch (action.type) {
    case C.API__RECEIVE_LIST:
      return produce(state, draft => {
        return action.resourceIdList;
      });
    default:
      return state;
  }
}

const resourceDataTypeReducers = combineReducers({ byId: resourceDataById, list: resourceListData });

export function resourceDataByType (state = {}, action) {
  switch (action.type) {
    case C.API__INVALIDATE:
    case C.API__INVALIDATE_LIST:
    case C.API__RECEIVE:
    case C.API__RECEIVE_LIST:
      return produce(state, draft => {
        draft[action.resourceType] = resourceDataTypeReducers(draft[action.resourceType], action);
      });
    default:
      return state;
  }
}

//
// App
//

// Selectors

function getAppById (state, resourceType, resourceId) {
  return state.resourceAppByType[resourceType] && state.resourceAppByType[resourceType].byId[resourceId];
}

function getAppList (state, resourceType) {
  return state.resourceAppByType[resourceType] && state.resourceAppByType[resourceType].list;
}

export function isCreatingSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).isCreating);
}

export function createErrorSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).createError);
}

export function createdResourceIdSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).createdResourceId);
}

export function isDeletingSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).isDeleting);
}

export function deleteErrorSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).deleteError);
}

export function isDeletedSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).isDeleted);
}

export function didInvalidateSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).didInvalidate);
}

export function didInvalidateListSelector (resourceType) {
  return (state) => (getAppList(state, resourceType) && getAppList(state, resourceType).didInvalidate);
}

export function fetchErrorSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).fetchError);
}

export function isFetchingSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).isFetching);
}

export function isListingSelector (resourceType) {
  return (state) => (getAppList(state, resourceType) && getAppList(state, resourceType).isListing);
}

export function isUpdatingSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).isUpdating);
}

export function listErrorSelector (resourceType) {
  return (state) => (getAppList(state, resourceType) && getAppList(state, resourceType).listError);
}

export function updateErrorSelector (resourceType, resourceId) {
  return (state) => (getAppById(state, resourceType, resourceId) && getAppById(state, resourceType, resourceId).updateError);
}

export function canCreateSelector (resourceType, tempResourceId) {
  return (state) => {
    const resource = getAppById(state, resourceType, tempResourceId);
    if (!resource) {
      return true;
    } else if (resource.isCreating) {
      return false;
    } else if (resource.createdResourceId) {
      return false;
    } else {
      return true;
    }
  };
}

export function canDeleteSelector (resourceType, resourceId) {
  return (state) => {
    const resource = getAppById(state, resourceType, resourceId);
    if (!resource) {
      return false;
    } else if (resource.isDeleting) {
      return false;
    } else if (resource.isDeleted) {
      return false;
    } else {
      return true;
    }
  };
}

export function canUpdateSelector (resourceType, resourceId) {
  return (state) => {
    const resource = getAppById(state, resourceType, resourceId);
    if (!resource) {
      return false;
    } else if (resource.didInvalidate) {
      return false;
    } else if (resource.isUpdating) {
      return false;
    } else {
      return true;
    }
  };
}

export function shouldFetchSelector (resourceType, resourceId) {
  return (state) => {
    const resource = getAppById(state, resourceType, resourceId);
    if (!resource) {
      return true;
    } else if (resource.isFetching) {
      return false;
    } else {
      return resource.didInvalidate;
    }
  };
}

export function shouldListSelector (resourceType) {
  return (state) => {
    const resourceList = state.resourceAppByType[resourceType] && state.resourceAppByType[resourceType].list;
    if (!resourceList) {
      return true;
    } else if (resourceList.isListing) {
      return false;
    } else if (resourceList.didInvalidate) {
      return true;
    } else {
      return !resourceList.didRequest;
    }
  };
}

// Reducers

function resourceApp (
  state = {
    createdResourceId: null,
    createError: null,
    isCreating: false,
    deleteError: null,
    isDeleted: false,
    isDeleting: false,
    isFetching: false,
    isUpdating: false,
    fetchError: null,
    didInvalidate: false,
    updateError: null
  },
  action
) {
  switch (action.type) {
    case C.API__CREATE:
      return produce(state, draft => {
        draft.isCreating = true;
        draft.createError = null;
      });
    case C.API__CREATE_ERROR:
      return produce(state, draft => {
        draft.isCreating = false;
        draft.createError = typeof action.error === 'string' ? new Error(action.error) : action.error;
      });
    case C.API__CREATED:
      return produce(state, draft => {
        draft.isCreating = false;
        draft.createdResourceId = action.createdResourceId;
      });
    case C.API__DELETE:
      return produce(state, draft => {
        draft.isDeleting = true;
        draft.deleteError = null;
      });
    case C.API__DELETE_ERROR:
      return produce(state, draft => {
        draft.isDeleting = false;
        draft.deleteError = typeof action.error === 'string' ? new Error(action.error) : action.error;
      });
    case C.API__DELETED:
      return produce(state, draft => {
        draft.isDeleting = false;
        draft.isDeleted = true;
      });
    case C.API__INVALIDATE:
      return produce(state, draft => {
        draft.didInvalidate = true;
      });
    case C.API__REQUEST:
      return produce(state, draft => {
        draft.isFetching = true;
        draft.didInvalidate = false;
        draft.fetchError = null;
        draft.updateError = null; // TODO?
      });
    case C.API__RECEIVE:
      return produce(state, draft => {
        draft.isFetching = false;
      });
    case C.API__RECEIVE_ERROR:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.fetchError = typeof action.error === 'string' ? new Error(action.error) : action.error;
      });
    case C.API__UPDATE:
      return produce(state, draft => {
        draft.isUpdating = true;
        draft.updateError = null;
      });
    case C.API__UPDATE_ERROR:
      return produce(state, draft => {
        draft.isUpdating = false;
        draft.updateError = typeof action.error === 'string' ? new Error(action.error) : action.error;
      });
    case C.API__UPDATED:
      return produce(state, draft => {
        draft.isUpdating = false;
      });
    default:
      return state;
  }
}

export function resourceAppById (state = {}, action) {
  switch (action.type) {
    case C.API__CREATE:
    case C.API__CREATE_ERROR:
    case C.API__CREATED:
    case C.API__DELETE:
    case C.API__DELETE_ERROR:
    case C.API__DELETED:
    case C.API__INVALIDATE:
    case C.API__RECEIVE:
    case C.API__RECEIVE_ERROR:
    case C.API__REQUEST:
    case C.API__UPDATE:
    case C.API__UPDATE_ERROR:
    case C.API__UPDATED:
      return produce(state, draft => {
        draft[action.resourceId] = resourceApp(draft[action.resourceId], action);
      });
    default:
      return state;
  }
}

export function resourceListApp (
  state = {
    didInvalidate: false,
    didRequest: false,
    isListing: false,
    listError: null
  },
  action) {
  switch (action.type) {
    case C.API__CREATED: // invalidate the list when a new goal is created
    case C.API__DELETED: // or when one is delete
    case C.API__INVALIDATE_LIST:
      return produce(state, draft => {
        draft.didInvalidate = true;
      });
    case C.API__RECEIVE_LIST:
      return produce(state, draft => {
        draft.isListing = false;
      });
    case C.API__RECEIVE_LIST_ERROR:
      return produce(state, draft => {
        draft.isListing = false;
        draft.listError = action.error;
      });
    case C.API__REQUEST_LIST:
      return produce(state, draft => {
        draft.isListing = true;
        draft.didInvalidate = false;
        draft.didRequest = true;
        draft.listError = null;
      });
    default:
      return state;
  }
}

const resourceAppTypeReducers = combineReducers({ byId: resourceAppById, list: resourceListApp });

export function resourceAppByType (state = {}, action) {
  switch (action.type) {
    case C.API__CREATE:
    case C.API__CREATE_ERROR:
    case C.API__CREATED:
    case C.API__DELETE:
    case C.API__DELETE_ERROR:
    case C.API__DELETED:
    case C.API__INVALIDATE:
    case C.API__INVALIDATE_LIST:
    case C.API__RECEIVE:
    case C.API__RECEIVE_ERROR:
    case C.API__RECEIVE_LIST:
    case C.API__RECEIVE_LIST_ERROR:
    case C.API__REQUEST:
    case C.API__REQUEST_LIST:
    case C.API__UPDATE:
    case C.API__UPDATE_ERROR:
    case C.API__UPDATED:
      return produce(state, draft => {
        draft[action.resourceType] = resourceAppTypeReducers(draft[action.resourceType], action);
      });
    default:
      return state;
  }
}

//
// Helpers
//

function resourceIsLoaded (state, resourceType, resourceId) {
  const resource = state.resourceAppByType[resourceType] && state.resourceAppByType[resourceType].byId[resourceId];
  if (!resource) {
    return false;
  } else if (resource.isDeleted) {
    return false;
  } else if (resource.fetchError) {
    return false;
  } else if (resource.didInvalidate) {
    return false;
  } else {
    return !!(state.resourceDataByType[resourceType] && state.resourceDataByType[resourceType].byId[resourceId]);
  }
}

function resourceListIsLoaded (state, resourceType, resourceId) {
  const resourceList = state.resourceAppByType[resourceType] && state.resourceAppByType[resourceType].list;
  if (!resourceList) {
    return false;
  } else if (resourceList.listError) {
    return false;
  } else if (resourceList.didInvalidate) {
    return false;
  } else {
    return !!(state.resourceDataByType[resourceType] && state.resourceDataByType[resourceType].list);
  }
}
