import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FormControl, FormGroup } from 'react-bootstrap';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as GA from '../redux/goalsActions';
import { CancelButton, DeleteButton, SaveButton } from './FormButtons';
import ErrorAlert from './ErrorAlert';
import RichTextField from './RichTextField';
import { useHistory } from 'react-router';
import { reportException } from '../libs/errors';

export default function GoalUpdateEditForm ({ goalId, update, close, showDelete }) {
  const history = useHistory();

  const dispatch = useDispatch();

  const isUpdating = useSelector(GA.isUpdatingSelector(goalId));
  const updateError = useSelector(GA.updateErrorSelector(goalId));
  const deleteError = useSelector(GA.deleteErrorSelector(goalId));

  // keep track of whether save or delete was pressed
  const [isDeleting, setIsDeleting] = useState(false);

  function handleSubmit (values, actions) {
    dispatch(GA.updateUpdate(goalId, update.id, values)).then(() => {
      actions.setSubmitting(false);
      history.push(`/goals/${goalId}/updates`);
    }).catch((e) => {
      reportException(e);
      // ignore
    });
  }

  function handleDelete (event) {
    event.preventDefault();
    setIsDeleting(true);
    const confirmed = window.confirm(
      'Are you sure you want to delete?'
    );
    if (confirmed) {
      dispatch(GA.deleteUpdate(goalId, update.id)).then(() => {
        setIsDeleting(false);
        history.push(`/goals/${goalId}/updates`);
      }).catch((e) => {
        reportException(e);
        setIsDeleting(false);
        // ignore
      });
    } else {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <ErrorAlert error={updateError} />
      <ErrorAlert error={deleteError} />
      <Formik initialValues={update} onSubmit={handleSubmit}>
        {({ submitting, dirty }) => (
          <Form>
            <Field name='text'>
              {({ field }) => (
                <FormGroup controlId={field.name}>
                  <RichTextField value={field.value} titleName={'title'} textName={'text'} />
                  <FormControl.Feedback type='invalid' style={{ display: 'block' }}>
                    <ErrorMessage name={field.name} />
                  </FormControl.Feedback>
                </FormGroup>
              )}
            </Field>
            <SaveButton disabled={submitting || !dirty || isUpdating} isLoading={(submitting || (!isDeleting && isUpdating))} />
            <CancelButton disabled={submitting || isUpdating || isDeleting}
              to={`/goals/${goalId}/updates`}
              dirty={dirty}
              confirm={'All changes will be lost, continue?'} />
            {showDelete && <DeleteButton disabled={submitting || isUpdating || isDeleting} isLoading={isDeleting} onClick={handleDelete} />}
          </Form>
        )}
      </Formik>
    </>
  );
}
