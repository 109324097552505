import * as API from './api';
import * as yup from 'yup';
import { reportException } from '../libs/errors';
import { API_NAME } from '../libs/constants';

export const createSchema = yup.object().shape({
  nickname: yup.string().required('Nickname is required').default(''),
  avatar: yup.string().default(''),
  goalId: yup.string().required('Goal is required')
}).noUnknown();

export const schema = createSchema.concat(yup.object().shape({
  userId: yup.string().required().default('uid'),
  advisershipId: yup.string().required().default('uid'),
  createdAt: yup.number().positive().required(),
  adviserStatus: yup.string().oneOf(['pending', 'active', 'deleted']).required()
}));

//
// API actions
//

export function del (advisershipId) {
  return API.del(API_NAME, `/adviserships/${advisershipId}`);
}

// TODO Check response codes and throw errors as neccessary
export function get (advisershipId) {
  return API.get(API_NAME, `/adviserships/${advisershipId}`);
}

export function list () {
  return API.get(API_NAME, `/adviserships`);
}

export function post (values) {
  try {
    createSchema.validateSync(values, { stripUnknown: false });
  } catch (error) {
    reportException(error);
    return Promise.reject(error);
  }

  return API.post(API_NAME, `/adviserships/`, { body: values });
}

export function put (advisership) {
  return API.put(API_NAME, `/adviserships/${advisership.advisershipId}`, { body: advisership });
}

//
// getters/setters
//

export function updateFields (advisership, nickname) {
  advisership.nickname = nickname;
}
