import { Container } from 'react-bootstrap';
import React from 'react';
import { GenericPageHeader } from '../components/PageHeaders';
import { COMPANY_NAME, SITE_NAME, SUPPORT_EMAIL } from '../libs/constants';

export default function Terms (props) {
  return (
    <Container className='lander'>
      <GenericPageHeader title={`${SITE_NAME} Terms of Service`} />

      <p>By using {SITE_NAME} (&#8220;Service&#8221;), you are agreeing to be bound by the following terms and conditions
        (&#8220;Terms of Service&#8221;).</p>

      <p>{COMPANY_NAME} (&#8220;Company&#8221;) reserves the right to update and change these Terms of Service without
        notice.</p>

      <p>Violation of any of the terms below may result in the termination of your account.</p>

      <h3>Account Terms</h3>

      <ol>
        <li>You are responsible for maintaining the security of your account and password. The Company cannot and will
          not be liable for any loss or damage from your failure to comply with this security obligation.
        </li>
        <li>You are responsible for all content posted and activity that occurs under your account.</li>
        <li>You may not use the Service for any illegal purpose or to violate any laws in your jurisdiction (including
          but not limited to copyright laws).
        </li>
        <li>You must provide a valid email address and any other information requested in order to complete the signup
          process.
        </li>
        <li>You must be a human. Accounts registered by “bots” or other automated methods are not permitted.</li>
      </ol>

      <h3>API Terms</h3>

      <p>Customers may not access their Service data via the Application Program Interface (&#8220;API&#8221;). Any use
        of the API, including use of the API through a third-party product that accesses the Service, is prohibited.</p>

      <h2>Cancellation and Termination</h2>

      <ol>
        <li>You are solely responsible for properly canceling your account. An email or phone request to cancel your
          account is not considered cancellation.
        </li>
        <li>The Company, in its sole discretion, has the right to suspend or terminate your account and refuse any and
          all current or future use of the Service for any reason at any time. Such termination of the Service will
          result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and
          relinquishment of all content in your account. The Company reserves the right to refuse service to anyone for
          any reason at any time.
        </li>
      </ol>

      <h3>Modifications to the Service and Prices</h3>

      <ol>
        <li>The Company reserves the right at any time and from time to time to modify or discontinue, temporarily or
          permanently, any part of the Service with or without notice.
        </li>
        <li>The Company shall not be liable to you or to any third party for any modification, price change, suspension
          or discontinuance of the Service.
        </li>
      </ol>

      <h3>Copyright and Content Ownership</h3>

      <ol>
        <li>All content posted on the Service must comply with U.S. copyright law.</li>
        <li>We claim no intellectual property rights over the material you provide to the Service. All materials
          uploaded remain yours.
        </li>
        <li>The Company does not pre-screen content, but reserves the right (but not the obligation) in their sole
          discretion to refuse or remove any content that is available via the Service.
        </li>
        <li>The look and feel of the Service is copyright© {COMPANY_NAME}. All rights reserved. You may not duplicate,
          copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written
          permission from the Company.
        </li>
      </ol>

      <h3>General Conditions</h3>

      <ol>
        <li>Your use of the Service is at your sole risk. The service is provided on an “as is” and “as available”
          basis.
        </li>
        <li>You understand that the Company uses third party vendors and hosting partners to provide the necessary
          hardware, software, networking, storage, and related technology required to run the Service.
        </li>
        <li>You must not modify, adapt or hack the Service.</li>
        <li>You must not modify another website so as to falsely imply that it is associated with the Service or the
          Company.
        </li>
        <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the
          Service, or access to the Service without the express written permission by the Company.
        </li>
        <li>We may, but have no obligation to, remove content and accounts that we determine in our sole discretion are
          unlawful or violates any party’s intellectual property or these Terms of Service.
        </li>
        <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Service
          customer, Company employee or officer will result in immediate account termination.
        </li>
        <li>You understand that the technical processing and transmission of the Service, including your content, may be
          transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and
          adapt to technical requirements of connecting networks or devices.
        </li>
        <li>We reserve the right to disable your account if your usage significantly exceeds the average usage of other
          Service customers.
        </li>
        <li>The Company does not warrant that (i) the service will meet your specific requirements, (ii) the service
          will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of
          the service will be accurate or reliable, (iv) the quality of any products, services, information, or other
          material purchased or obtained by you through the service will meet your expectations, and (v) any errors in
          the Service will be corrected.
        </li>
        <li>You expressly understand and agree that the Company shall not be liable for any direct, indirect,
          incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of
          profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the
          possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost
          of procurement of substitute goods and services resulting from any goods, data, information or services
          purchased or obtained or messages received or transactions entered into through or from the service; (iii)
          unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third
          party on the service; (v) or any other matter relating to the service.
        </li>
        <li>The failure of the Company to exercise or enforce any right or provision of the Terms of Service shall not
          constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between
          you and the Company and govern your use of the Service, superceding any prior agreements between you and the
          Company (including, but not limited to, any prior versions of the Terms of Service).
        </li>
        <li>Questions about the Terms of Service should be sent to <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
        </li>
      </ol>

      <p>Any new features that augment or enhance the current Service, including the release of new tools and resources,
        shall be subject to the Terms of Service. Continued use of the Service after any such changes shall constitute
        your consent to such changes.</p>

      <p>Adapted from the <a href='https://github.com/basecamp/policies'>Basecamp open-source policies</a> / <a
        href='https://creativecommons.org/licenses/by/4.0/'>CC BY 4.0</a></p>
    </Container>
  );
}
