import React from 'react';
import { Container } from 'react-bootstrap';
import { GoalPageHeader } from '../components/PageHeaders';
import { useGoal } from '../redux/goalsActions';
import GoalUpdateEditForm from '../components/GoalUpdateEditForm';
import { GenericNoData } from '../components/NoData';

export default function GoalUpdatesEdit (props) {
  let goalId = props.match.params.id;
  let updateId = props.match.params.updateId;

  const [goal, noGoal] = useGoal(goalId);

  if (noGoal) { return <GenericNoData noData={noGoal} />; }

  const update = goal.updates.find((u) => (u.id === updateId));

  return (
    <Container>
      <GoalPageHeader goal={goal} />
      <GoalUpdateEditForm goalId={goalId} update={update} close={() => { props.history.push(); }} showDelete />
    </Container>
  );
}
