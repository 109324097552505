import { useGoal } from '../redux/goalsActions';
import { GenericNoData } from '../components/NoData';
import { Col, Container, Row } from 'react-bootstrap';
import { GoalPageHeader } from '../components/PageHeaders';
import React from 'react';
import { fromISODate } from '../libs/dates';

export default function GoalInfo (props) {
  let goalId = props.match.params.id;

  const [goal, noGoal] = useGoal(goalId);

  if (noGoal) { return <GenericNoData noData={noGoal} />; }

  return (
    <Container>
      <GoalPageHeader goal={goal} editTo={`/goals/${goalId}/info/edit`} />
      <Row as='dl'>
        <Col as='dt' sm={3}>My Nickname <small className='text-muted'>(for advisers)</small></Col>
        <Col as='dd' sm={9}>{goal.nickname}</Col>
        <Col as='dt' sm={3}>Title</Col>
        <Col as='dd' sm={9}>{goal.title}</Col>
        <Col as='dt' sm={3}>Dates</Col>
        <Col as='dd' sm={9}>{fromISODate(goal.startAt).toDateString()} - {fromISODate(goal.endAt).toDateString()}</Col>
        <Col as='dt' sm={3}>KPI (Key Performance Indicator)</Col>
        <Col as='dd' sm={9}>{goal.kpis && goal.kpis[0].name}</Col>
      </Row>
    </Container>
  );
}
