import React from 'react';
import { Alert } from 'react-bootstrap';

export default function ErrorAlert ({ error }) {
  if (!error) {
    return null;
  }

  return (<Alert variant={'danger'}>{error.message ? error.message : error}</Alert>);
}
