import React from 'react';
import { CancelButton, DeleteButton, SaveButton } from '../components/FormButtons';
import { Formik, Form } from 'formik';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useGoal } from '../redux/goalsActions';
import * as Advisership from '../models/advisership';
import * as AA from '../redux/advisershipsActions';
import GoalAdviserEditFields from '../components/GoalAdviserEditFields';
import ErrorAlert from '../components/ErrorAlert';
import { GoalAdviserPageHeader } from '../components/PageHeaders';
import { GenericNoData } from '../components/NoData';
import { reportException } from '../libs/errors';

export default function GoalsEdit (props) {
  let goalId = props.match.params.id;
  let advisershipId = props.match.params.advisershipId;

  const [goal, noGoal] = useGoal(goalId);
  const [advisership, noAdvisership] = AA.useAdvisership(advisershipId);

  const dispatch = useDispatch();

  const isUpdating = useSelector(AA.isUpdatingSelector(goalId));
  const isDeleting = useSelector(AA.isDeletingSelector(advisershipId));

  const updateError = useSelector(AA.updateErrorSelector(advisershipId));
  const deleteError = useSelector(AA.deleteErrorSelector(advisershipId));

  if (noGoal) { return <GenericNoData noData={noGoal} />; }
  if (noAdvisership) { return <GenericNoData noData={noAdvisership} />; }

  function handleSubmit (values, actions) {
    dispatch(AA.updateDetails(advisershipId, values.nickname)).then(() => {
      actions.setSubmitting(false);
      props.history.push(`/goals/${goalId}/advisers/${advisershipId}`);
    }).catch((e) => {
      reportException(e);
      // ignore
    });
  }

  async function handleDelete (event) {
    event.preventDefault();

    const confirmed = window.confirm(
      'Are you sure you want to delete this adviser?'
    );
    if (confirmed) {
      dispatch(AA.deleteIfPossible(advisershipId)).then(() => {
        props.history.push(`/goals/${goalId}/advisers`);
      }).catch((e) => {
        reportException(e);
        // ignore
      });
    }
  }

  return (
    <Container>
      <GoalAdviserPageHeader goal={goal} title={advisership.nickname} />
      <ErrorAlert error={updateError} />
      <ErrorAlert error={deleteError} />
      <Formik initialValues={advisership} onSubmit={handleSubmit} validationSchema={Advisership.schema} >
        {({ submitting, dirty, values, errors, touched }) => (
          <Form>
            <GoalAdviserEditFields values={values} errors={errors} touched={touched} />
            <SaveButton disabled={submitting || !dirty || isUpdating} isLoading={submitting || isUpdating} />
            <CancelButton to={`/goals/${goalId}/advisers/${advisership.advisershipId}`} />
            <DeleteButton isLoading={isDeleting} onClick={handleDelete} />
          </Form>
        )}
      </Formik>
    </Container>
  );
}
