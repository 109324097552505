import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { resourceAppByType, resourceDataByType } from './apiReducers';

const rootReducer = combineReducers({
  resourceAppByType,
  resourceDataByType
});

export default function configureStore (preloadedState) {
  let middleware = [thunkMiddleware];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger());
  }
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
