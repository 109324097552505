import { Alert, Col, Container, Row } from 'react-bootstrap';
import ErrorAlert from './ErrorAlert';
import React from 'react';
import * as causes from '../redux/hooks';
import { reportException } from '../libs/errors';

export function GenericNoData ({ noData }) {
  if (noData.cause === causes.NO_DATA_CAUSE_LOADING) {
    return (<Container><Row><Col>Loading...</Col></Row></Container>);
  } else if (noData.cause === causes.NO_DATA_CAUSE_DELETED) {
    return (<Container><Row><ErrorAlert error={'Resource deleted.'} /></Row></Container>);
  } else if (noData.cause === causes.NO_DATA_CAUSE_ERROR) {
    reportException(noData.error);
    return (<Container><Row><Col><ErrorAlert error={noData.error} /></Col></Row></Container>);
  } else if (noData.cause === causes.NO_DATA_CAUSE_UNKNOWN) {
    reportException(new Error('NO_DATA_CAUSE_UNKNOWN'));
    return (<Container><Row><ErrorAlert error={'Resource missing.'} /></Row></Container>);
  } else {
    let e = new Error('Unknown cause');
    e.cause = noData;
    reportException(e);
    return (<Container><Row><Col><ErrorAlert error={noData.cause} /></Col></Row></Container>);
  }
}

export function TokenAdviserNoData ({ noData }) {
  if (noData.cause === causes.NO_DATA_CAUSE_DELETED) {
    return (<Container><Row><Alert variant={'success'}>Your account has been deleted.</Alert></Row></Container>);
  } else if (noData.cause === causes.NO_DATA_CAUSE_ERROR && noData.error.response) {
    if (noData.error.response.status === 404) {
      return (<Container><Row><Col><ErrorAlert error={'This link has been canceled!  Contact the owner to get a new link so you can continue to track their progress.'} /></Col></Row></Container>);
    } else if (noData.error.response.status === 410) {
      return (<Container><Row><Col><ErrorAlert error={'This goal has been deleted by the owner!'} /></Col></Row></Container>);
    }
  }
  return <GenericNoData noData={noData} />;
}

export function AdviserActivationNoData ({ noData }) {
  if (noData.cause === causes.NO_DATA_CAUSE_DELETED) {
    return (<Container><Row><Alert variant={'success'}>Your activation has been canceled.</Alert></Row></Container>);
  } else if (noData.cause === causes.NO_DATA_CAUSE_ERROR && noData.error.response) {
    if (noData.error.response.status === 404) {
      return (<Container><Row><Col><ErrorAlert error={'This link is no good!  Contact the owner to get a new link so you can track their progress.'} /></Col></Row></Container>);
    } else if (noData.error.response.status === 410) {
      return (<Container><Row><Col><ErrorAlert error={'This link has already been used!  For your privacy, the activation link can only be used once.  Talk to the owner to get a new link so you can track their progress.'} /></Col></Row></Container>);
    }
  }
  return <GenericNoData noData={noData} />;
}
