import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function LoaderButton ({
  isLoading,
  text,
  loadingText,
  className = '',
  disabled = false,
  ...props
}) {
  return (
    <Button className={`LoaderButton ${className}`} disabled={disabled || isLoading} {...props} >
      {isLoading && <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />}
      {!isLoading ? text : loadingText}
    </Button>
  );
}

export function SaveButton ({ text = 'Save', loadingText = 'Saving…', ...props }) {
  return (<LoaderButton
    block
    variant='primary'
    size='large'
    text={text}
    loadingText={loadingText}
    type='submit'
    {...props}
  />);
}

function handleCancel (event, dirty, message) {
  // show a confirmation only if the form has been edited.
  const confirmed = !dirty ? true : window.confirm(message);
  if (!confirmed) {
    event.preventDefault();
  }
}

export function CancelButton ({ text = 'Cancel', dirty, confirm, to, ...props }) {
  return (<Button as={Link}
    to={to}
    block
    variant='outline-secondary'
    size='large'
    onClick={confirm && ((e) => handleCancel(e, dirty, confirm))}
    {...props}>{text}</Button>);
}

export function CancelClickButton ({ text = 'Cancel', dirty, confirm, onClick, ...props }) {
  return (<Button block
    variant='outline-secondary'
    size='large'
    onClick={onClick}
    {...props}>{text}</Button>);
}

export function CreateButton ({ text = 'Create', loadingText = 'Creating…', ...props }) {
  return (<SaveButton text={text} loadingText={loadingText} {...props} />);
}

export function DeleteButton ({ text = 'Delete', loadingText = 'Deleting…', ...props }) {
  return (<LoaderButton
    block
    variant='outline-danger'
    size='large'
    text={text}
    loadingText={loadingText}
    {...props}
  />);
}
