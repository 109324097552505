import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

export default function GoalAdviserEditFields ({ values, errors, touched }) {
  return (
    <>
      <Field name='nickname'>
        {({ field }) => (
          <FormGroup controlId={field.name}>
            <FormLabel>Nickname</FormLabel>
            <FormControl {...field} isInvalid={!!(errors[field.name] && touched[field.name])} type='text' />
            <FormControl.Feedback type='invalid'>
              <ErrorMessage name={field.name} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
    </>
  );
}
