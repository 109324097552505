import React, { useState } from 'react';
import { CancelButton, CreateButton } from '../components/FormButtons';
import { useDispatch, useSelector } from 'react-redux';
import * as AA from '../redux/advisershipsActions';
import { Form, Formik } from 'formik';
import * as Advisership from '../models/advisership';
import { Container } from 'react-bootstrap';
import * as shortid from 'shortid';
import { Redirect } from 'react-router';
import GoalAdviserEditFields from '../components/GoalAdviserEditFields';
import ErrorAlert from '../components/ErrorAlert';
import { useGoal } from '../redux/goalsActions';
import { GoalAdviserPageHeader } from '../components/PageHeaders';
import { GenericNoData } from '../components/NoData';
import { reportException } from '../libs/errors';

export default function GoalAdviserNew (props) {
  const goalId = props.match.params.id;

  const [tempId] = useState(shortid.generate());

  const dispatch = useDispatch();

  const [goal, noGoal] = useGoal(goalId);

  const isCreating = useSelector(AA.isCreatingSelector(tempId));
  const createError = useSelector(AA.createErrorSelector(tempId));
  const createdId = useSelector(AA.createdIdSelector(tempId));

  if (noGoal) { return <GenericNoData noData={noGoal} />; }

  function handleSubmit (values, actions) {
    dispatch(AA.createIfPossible(tempId, { goalId, ...values })).finally(() => {
      actions.setSubmitting(false);
    }).catch((e) => {
      reportException(e);
      // ignore
    });
  }

  let initialValues = Advisership.createSchema.default();
  initialValues.goalId = goalId;

  if (createdId) {
    return (<Redirect to={`/goals/${goalId}/advisers/${createdId}?showQr=true`} />);
  } else {
    return (
      <Container>
        <GoalAdviserPageHeader goal={goal} title='Add A New Adviser' />
        <div className='NewGoalAdviser'>
          <ErrorAlert error={createError} />
          <p>Invite someone you respect and trust to track your progress.  Knowing they're watching will help keep you on track!</p>
          <p className='muted'><small>After entering a nickname we will generate a QR code your adviser can scan with their phone's camera to complete the sign up.</small></p>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={Advisership.createSchema}
            render={({ submitting, dirty, values, errors, touched }) => (
              <Form>
                {createError && <div>{createError.message}</div>}
                <GoalAdviserEditFields values={values} errors={errors} touched={touched} />
                <CreateButton disabled={submitting || !dirty || isCreating} isLoading={submitting || isCreating} />
                <CancelButton to={`/goals/${goalId}/advisers`} />
              </Form>
            )}
          />
        </div>
      </Container>);
  }
}
