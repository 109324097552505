import React from 'react';
import * as AA from '../redux/advisersActions';
import { Col, Container, Row } from 'react-bootstrap';
import { TokenAdviserPageHeader } from '../components/PageHeaders';
import * as AdvisershipDisplay from '../components/AdvisershipDisplay';
import { TokenAdviserNoData } from '../components/NoData';

export default function TokenAdviserInfo (props) {
  const adviserId = props.match.params.id;

  const [adviser, noAdviser] = AA.useAdviser(adviserId);
  if (noAdviser) { return <TokenAdviserNoData noData={noAdviser} />; }

  return (
    <Container>
      <TokenAdviserPageHeader adviser={adviser} editTo={`/advisers/${adviserId}/info/edit`} />
      <Row className='mb-2'>
        <Col style={{ maxWidth: '6em' }}>
          <AdvisershipDisplay.Image advisership={adviser} />
        </Col>
        <Col className='d-flex align-items-end'>
          <h3>{adviser.nickname}</h3>
        </Col>
      </Row>
      <Row as='dl'>
        <Col as='dt' xs={12}>Email</Col>
        <Col as='dd' xs={12}>{adviser.maskedEmail || 'none'}</Col>
        <Col as='dt' xs={12}>Send Notifications</Col>
        <Col as='dd' xs={12}>{adviser.notificationOptIn ? 'yes' : 'no'}</Col>
        <Col as='dt' xs={12}>Last Notifcation Sent</Col>
        <Col as='dd' xs={12}>{adviser.lastNotificationSentAt ? new Date(adviser.lastNotificationSentAt).toLocaleDateString() : 'never'}</Col>
      </Row>
    </Container>
  );
}
