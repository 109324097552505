import React from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { COLORS } from '../libs/bootstrapHelpers';

const ICON_SIZE = 'lg';

function Header ({ children }) {
  return (<div className='mb-2'>{children}</div>);
}

function Title ({ backTo, children }) {
  return (
    <div className='d-flex border-bottom mb-0 pb-1 align-items-center'>
      {backTo && <div className='d-flex justify-content-center'>
        <Link to={backTo} className='pl-0 pr-3'><FontAwesomeIcon size={ICON_SIZE} icon={'chevron-left'} /></Link>
      </div>
      }
      <div className='font-weight-bold text-muted'>{children}</div>
    </div>
  );
}

function NavBar ({ children }) {
  return (<div className='d-flex justify-content-center'><Nav className='mb-1'>{children}</Nav></div>);
}

function PageNav ({ to, children }) {
  return (<LinkContainer activeStyle={{ borderBottom: `2px solid ${COLORS.primary}` }} to={to}><Nav.Link
    className='py-0'>{children}</Nav.Link></LinkContainer>);
}

function Edit ({ to }) {
  return (!to ? <></> : <div className='d-flex justify-content-end'><Link to={to}>Edit</Link></div>);
}

export function GoalPageHeader ({ goal, editTo }) {
  return (
    <Header>
      <Title backTo={'/'}>{goal.title}</Title>
      <NavBar>
        <PageNav to={`/goals/${goal.goalId}/kpis`}><FontAwesomeIcon size={ICON_SIZE} icon={'chart-line'} /></PageNav>
        <PageNav to={`/goals/${goal.goalId}/updates`}><FontAwesomeIcon size={ICON_SIZE} icon={'newspaper'} /></PageNav>
        <PageNav to={`/goals/${goal.goalId}/advisers`}><FontAwesomeIcon size={ICON_SIZE} icon={'users'} /></PageNav>
        <PageNav to={`/goals/${goal.goalId}/info`}><FontAwesomeIcon size={ICON_SIZE} icon={'info-circle'} /></PageNav>
      </NavBar>
      <Edit to={editTo} />
    </Header>);
}

export function GoalNewPageHeader () {
  return (
    <Header>
      <Title backTo={'/'}>New Goal</Title>
    </Header>);
}

export function GoalAdviserPageHeader ({ goal, title, editTo }) {
  return (
    <Header>
      <Title backTo={`/goals/${goal.goalId}/advisers`}>{title}</Title>
      <Edit to={editTo} />
    </Header>);
}

export function GenericPageHeader ({ title }) {
  return (<Header><Title>{title}</Title></Header>);
}

export function TokenAdviserPageHeader ({ adviser, editTo }) {
  return (
    <Header>
      <Title><small className='text-muted'>{adviser.goal.nickname}'s</small><br />{adviser.goal.title}</Title>
      <NavBar>
        <PageNav to={`/advisers/${adviser.adviserSecretId}/updates`}><FontAwesomeIcon size={ICON_SIZE} icon={'newspaper'} /></PageNav>
        <PageNav to={`/advisers/${adviser.adviserSecretId}/kpis`}><FontAwesomeIcon size={ICON_SIZE} icon={'chart-line'} /></PageNav>
        <PageNav to={`/advisers/${adviser.adviserSecretId}/info`}><FontAwesomeIcon size={ICON_SIZE} icon={'user'} /></PageNav>
      </NavBar>
      <Edit to={editTo} />
    </Header>);
}
