import produce from 'immer';
import * as AA from './apiActions';
import * as AR from './apiReducers';
import * as Goal from '../models/goal';
import * as C from './constants';
import { useResource } from './hooks';

//
// Hooks
//

export function useGoal (goalId) {
  return useResource(
    () => fetchIfNeeded(goalId),
    () => isFetchingSelector(goalId),
    () => isDeletedSelector(goalId),
    () => fetchErrorSelector(goalId),
    () => dataSelector(goalId)
  );
}

export function useGoalsList () {
  return useResource(
    listIfNeeded,
    isListingSelector,
    () => (state) => false, // it's not possible for the list to be deleted, so this is a selector that always returns false
    listErrorSelector,
    listAllDataSelector
  );
}

//
// Selectors
//

export function dataSelector (goalId) { return AR.dataSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function listDataSelector () { return AR.listDataSelector(C.RESOURCE_TYPE_GOAL); }
export function listAllDataSelector () { return AR.listAllDataSelector(C.RESOURCE_TYPE_GOAL); }

export function isCreatingSelector (goalId) { return AR.isCreatingSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function createErrorSelector (goalId) { return AR.createErrorSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function createdIdSelector (goalId) { return AR.createdResourceIdSelector(C.RESOURCE_TYPE_GOAL, goalId); }

export function isDeletingSelector (goalId) { return AR.isDeletingSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function deleteErrorSelector (goalId) { return AR.deleteErrorSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function isDeletedSelector (goalId) { return AR.isDeletedSelector(C.RESOURCE_TYPE_GOAL, goalId); }

export function didInvalidateSelector (goalId) { return AR.didInvalidateSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function didInvalidateListSelector () { return AR.didInvalidateListSelector(C.RESOURCE_TYPE_GOAL); }

export function isFetchingSelector (goalId) { return AR.isFetchingSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function fetchErrorSelector (goalId) { return AR.fetchErrorSelector(C.RESOURCE_TYPE_GOAL, goalId); }

export function isListingSelector () { return AR.isListingSelector(C.RESOURCE_TYPE_GOAL); }
export function listErrorSelector () { return AR.listErrorSelector(C.RESOURCE_TYPE_GOAL); }

export function isUpdatingSelector (goalId) { return AR.isUpdatingSelector(C.RESOURCE_TYPE_GOAL, goalId); }
export function updateErrorSelector (goalId) { return AR.updateErrorSelector(C.RESOURCE_TYPE_GOAL, goalId); }

//
// Actions
//

export function createIfPossible (tempGoalId, values) {
  return AA.createIfPossible(C.RESOURCE_TYPE_GOAL, tempGoalId, () =>
    Goal.post(values)
      .then(goal => goal.goalId)
  );
}

export function deleteIfPossible (goalId) {
  return AA.deleteIfPossible(C.RESOURCE_TYPE_GOAL, goalId, () => Goal.del(goalId));
}

export function fetchIfNeeded (goalId) {
  return AA.fetchIfNeeded(C.RESOURCE_TYPE_GOAL, goalId, () => Goal.get(goalId));
}

export function invalidate (goalId) {
  return AA.invalidate(C.RESOURCE_TYPE_GOAL, goalId);
}

export function invalidateList () {
  return AA.invalidateList(C.RESOURCE_TYPE_GOAL);
}

export function listIfNeeded () {
  return AA.listIfNeeded(C.RESOURCE_TYPE_GOAL, () =>
    Goal.list().then(goals => goals.map(goal => ({ resourceId: goal.goalId, resource: goal })))
  );
}

export function updateDetails (goalId, { nickname, title, startAt, endAt, kpis }) {
  return (dispatch, getState) => {
    let goal = AR.dataSelector(C.RESOURCE_TYPE_GOAL, goalId)(getState());
    let nextGoal = produce(goal, draft => {
      Goal.updateFields(draft, nickname, title, startAt, endAt);
      Goal.updateKpis(draft, kpis);
    });
    return dispatch(AA.putResource(C.RESOURCE_TYPE_GOAL, goalId, () => Goal.put(nextGoal), () => Goal.get(goalId)));
  };
}

export function updateKpiDatapoints (goalId, kpiName, isoDates, values) {
  return (dispatch, getState) => {
    let goal = AR.dataSelector(C.RESOURCE_TYPE_GOAL, goalId)(getState());
    let nextGoal = produce(goal, draft => {
      Goal.updateKpiDatapoints(draft, kpiName, isoDates, values);
    });
    return dispatch(AA.putResource(C.RESOURCE_TYPE_GOAL, goalId, () => Goal.put(nextGoal), () => Goal.get(goalId)));
  };
}

export function updateUpdate (goalId, updateId, updateInfo) {
  return (dispatch, getState) => {
    let goal = AR.dataSelector(C.RESOURCE_TYPE_GOAL, goalId)(getState());
    let nextGoal = produce(goal, draft => {
      Goal.updateUpdate(draft, { ...updateInfo, id: updateId });
    });
    return dispatch(AA.putResource(C.RESOURCE_TYPE_GOAL, goalId, () => Goal.put(nextGoal), () => Goal.get(goalId)));
  };
}

export function deleteUpdate (goalId, updateId) {
  return (dispatch, getState) => {
    let goal = AR.dataSelector(C.RESOURCE_TYPE_GOAL, goalId)(getState());
    let nextGoal = produce(goal, draft => {
      Goal.deleteUpdate(draft, updateId);
    });
    return dispatch(AA.putResource(C.RESOURCE_TYPE_GOAL, goalId, () => Goal.put(nextGoal), () => Goal.get(goalId)));
  };
}
