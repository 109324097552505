import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import * as moment from 'moment';
import * as isTouchDevice from 'is-touch-device';
import { calcDateRange, fromISODate, toISODate } from '../libs/dates';

export default function KpiChart ({ kpiName, dates, datapoints, setUpdateNode }) {
  let endI = dates.length - 1;
  const data = [];
  for (let i = 0; i <= endI; i++) {
    data.push([moment(dates[i]).valueOf(), datapoints[i]]);
  }

  let initialStartI = calcInitialStartI(endI, isTouchDevice());

  let chartName = `chart-${kpiName}`;

  const [chartOptionsArea] = useState({
    chart: {
      id: chartName,
      toolbar: {
        autoSelected: 'pan',
        show: false
      }
    },
    colors: ['#546E7A'],
    stroke: {
      width: 2
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      }
    },
    tooltip: {
      x: {
        show: true
      },
      y: {
        title: {
          formatter: () => {}
        }
      }
    },
    annotations: {
      xaxis: [{
        x: fromISODate(toISODate(new Date())).valueOf(),
        strokeDashArray: 0,
        borderColor: '#008FFB'
      }]
    }
  });

  if (setUpdateNode) {
    chartOptionsArea.chart.events = {
      click: function (event, chartContext, config) {
        if (config.dataPointIndex >= 0) {
          setUpdateNode(config.dataPointIndex);
        }
      },
      mouseMove: isTouchDevice() && function (event, chartContext, config) {
        if (config.dataPointIndex >= 0) {
          setUpdateNode(config.dataPointIndex);
        }
      }
    };
  }

  const [chartOptionsBrush] = useState({
    chart: {
      id: 'chartBrush',
      brush: {
        target: chartName,
        enabled: true,
        autoScaleYaxis: false
      },
      selection: {
        enabled: true,
        xaxis: {
          min: fromISODate(dates[initialStartI]).valueOf(),
          max: fromISODate(dates[endI]).valueOf()
        }
      }
    },
    colors: ['#008FFB'],
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1
      }
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      tickAmount: 2
    }
  });

  return (
    <div id='charts'>
      <div id='chart1'>
        <ReactApexChart options={chartOptionsArea} series={[{ data: data }]} type='line' height='230' />
      </div>
      <div id='chart2'>
        <ReactApexChart options={chartOptionsBrush} series={[{ data: data }]} type='area' height='130' />
      </div>
    </div>
  );
}

/**
 * Calculate the date range to show on the chart.  In general this is from the start of the goal
 * to today or the end of the goal (whichever is later).  To handle corner cases with the chart,
 * pad the range by starting 1 day before the goal start and ending at least a week into the
 * future (for active charts).
 * @param goalStartAt
 * @param goalEndAt
 * @returns {[]} The ISODates that fall within the calculated range.
 */
export function caclcPaddedDateRange (goalStartAt, goalEndAt) {
  // start 1 day before the goal to work around some weirdness with apexcharts
  let startMoment = moment(goalStartAt).subtract(1, 'day');
  let endMoment = moment().add(7, 'days'); // default to showing some days into the future from today
  if (goalEndAt && endMoment.isAfter(goalEndAt)) {
    endMoment = moment(goalEndAt).add(1, 'day');
  }
  return calcDateRange(toISODate(startMoment), toISODate(endMoment));
}

/**
 * Calculate where to stop datapoints; the min of the end of the goal or today. This allows
 * padding the chart series with 'undefined' for future points
 * @param goalEndAt
 * @returns {string} an ISO Date string
 */
export function calcDatapointEndAt (goalEndAt) {
  let today = toISODate(new Date());
  if (goalEndAt && goalEndAt < today) {
    // the goal ended earlier than today.
    return goalEndAt;
  } else {
    return today;
  }
}

/**
 * Calculate the dates array that should be passed to the UpdateForm.
 * The array is calculated from the start of the goal to the datapointEnd, and handles
 * padding before the start of the goal by putting null values in the array.
 * @param dates - array of ISO date strings
 * @param goalStartAt - ISO date string
 * @param datapointEndAt - ISO date string
 * @returns {[]} an array of null values and ISO date strings
 */
export function calcUpdateableDates (dates, goalStartAt, datapointEndAt) {
  let lastI = dates.findIndex((d) => d === datapointEndAt) + 1;

  let updateableDates = [];
  for (let i = 0; i < lastI; i++) {
    if (dates[i] >= goalStartAt) {
      updateableDates.push(dates[i]);
    } else {
      updateableDates.push(null);
    }
  }

  return updateableDates;
}

/**
 * Calculates the starting position of the brush chart selection (the end of the selection always
 * starts as the end of the chart).  The general idea is on touch devices make sure the selection is
 * at least 25% of the chart so the selection is a big enough target.  On mouse devices start with at
 * most 21 days so the main chart has adequate zoom.
 * @param endI
 * @param isTouch
 * @returns {number}
 */
function calcInitialStartI (endI, isTouch) {
  let desiredLength = 21; // Pick 21 days as a reasonable starting selection length.
  if (isTouch) {
    // But on a touch device, that might be too small to manipulate, so make sure
    // the length is at least 25% of the total length.
    desiredLength = Math.max(desiredLength, Math.floor(endI / 4));
  }

  // Calculate the starting position needed to get the desired length.  If the desired
  // length is longer than the actual length, start from 0.
  return Math.max(0, endI - desiredLength);
}
