import React from 'react';
import { Col, Container, Figure, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import * as AdvisershipDisplay from '../components/AdvisershipDisplay';
import { useAdvisershipsList } from '../redux/advisershipsActions';
import { useGoal } from '../redux/goalsActions';
import { GoalPageHeader } from '../components/PageHeaders';
import { GenericNoData } from '../components/NoData';

function AdvisershipCol (props) {
  return <Col xs='4' md='3' className='text-center'><div className='mx-auto' style={{ maxWidth: '6em' }}>{props.children}</div></Col>;
}

export default function GoalAdviserList (props) {
  const goalId = props.match.params.id;

  const [goal, noGoal] = useGoal(goalId);
  const [adviserships, noAdviserships] = useAdvisershipsList(goalId);

  if (noGoal) { return <GenericNoData noData={noGoal} />; }
  if (noAdviserships) { return <GenericNoData noData={noAdviserships} />; }

  return (
    <Container>
      <GoalPageHeader goal={goal} />
      <Row>
        {adviserships.map((advisership, i) => {
          let colors = AdvisershipDisplay.colors(advisership.adviserStatus);
          return (
            <AdvisershipCol key={i}>
              <LinkContainer key='new' to={`/goals/${goalId}/advisers/${advisership.advisershipId}`}>
                <Figure className='w-100'>
                  <AdvisershipDisplay.Image {...{ advisership }} />
                  <Figure.Caption className={`text-wrap text-truncate ${colors.text}`}>{advisership.nickname}</Figure.Caption>
                </Figure>
              </LinkContainer>
            </AdvisershipCol>
          );
        })}
        <AdvisershipCol>
          <LinkContainer key='new' to={`/goals/${goalId}/advisers/new`}>
            <Figure className='w-100'>
              <AdvisershipDisplay.CreateImage />
              <Figure.Caption>New Adviser</Figure.Caption>
            </Figure>
          </LinkContainer>
        </AdvisershipCol>
      </Row>
    </Container>
  );
}
