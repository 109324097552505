import * as API from './api';
import { API_NAME } from '../libs/constants';

//
// API actions
//

// TODO Check response codes and throw errors as neccessary
export function del (activationSecretId) {
  return API.del(API_NAME, `/adviser_activations/${activationSecretId}`);
}

export function get (activationSecretId) {
  return API.get(API_NAME, `/adviser_activations/${activationSecretId}`);
}
