import React, { Component, Fragment } from 'react';
import * as Auth from './libs/auth';
import { Link, withRouter } from 'react-router-dom';
import { Button, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Routes from './Routes';
import * as Sentry from '@sentry/browser';
import { faCaretDown, faChevronLeft, faCaretRight, faEdit, faInfoCircle, faPlus, faSync, faTimes, faChartLine, faUser, faUsers, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { reportException } from './libs/errors';
import { toISODate } from './libs/dates';
import { COMPANY_NAME, SITE_NAME } from './libs/constants';
library.add(faCaretDown, faChevronLeft, faCaretRight, faEdit, faInfoCircle, faPlus, faSync, faTimes, faChartLine, faUser, faUsers, faNewspaper);

function FeedbackButton () {
  return (<Button variant='link' onClick={() => {
    let e = Sentry.captureMessage(`User feedback ${toISODate(new Date())}`);
    Sentry.showReportDialog({
      eventId: e.eventId,
      title: 'Give Us Feedback!',
      subtitle: `Help us improve ${SITE_NAME}`,
      subtitle2: '',
      labelComments: 'Comments',
      labelSubmit: 'Send Feedback'
    });
  }
  }><small>Feedback</small></Button>);
}

class App extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount () {
    try {
      let user = await Auth.currentSession();
      if (user !== null) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      reportException(e);
      alert(e);
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push('/login');
  }

  render () {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <>
        <div className='pt-0 px-0 pb-5 mb-0 App container min-vh-100'>
          <Navbar className='mt-0 mb-2' collapseOnSelect expand='lg' bg='light' variant='light' >
            <Navbar.Brand className='font-weight-bolder'>
              <Link to='/'>{SITE_NAME}</Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse className='justify-content-end'>
              <Nav>
                {this.state.isAuthenticated
                  ? <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link>
                  : <Fragment>
                    <LinkContainer to='/signup'>
                      <Nav.Link>Signup</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to='/login'>
                      <Nav.Link>Login</Nav.Link>
                    </LinkContainer>
                  </Fragment>
                }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes childProps={childProps} />
        </div>
        <div className='AppFooter px-0 container mt-n5 h-5'>
          <Navbar className='justify-content-center mb-0 p-0 h-50' bg='light' variant='light'>
            <LinkContainer to='/terms'><Nav.Link className='py-0'><small>Terms</small></Nav.Link></LinkContainer>
            <LinkContainer to='/privacy'><Nav.Link className='py-0'><small>Privacy</small></Nav.Link></LinkContainer>
            <FeedbackButton />
          </Navbar>
          <Navbar className='justify-content-center mb-0 p-0 h-50' bg='light' variant='light'>
            <Nav.Item><small>{COMPANY_NAME} © 2020</small></Nav.Item>
          </Navbar>
        </div>
      </>
    );
  }
}

export default withRouter(App);
