import React from 'react';
import { Container } from 'react-bootstrap';
import { GoalPageHeader } from '../components/PageHeaders';
import { useGoal } from '../redux/goalsActions';
import GoalUpdateEditForm from '../components/GoalUpdateEditForm';
import { newUpdate } from '../models/goal';
import { GenericNoData } from '../components/NoData';

export default function GoalUpdatesNew (props) {
  let goalId = props.match.params.id;

  const [goal, noGoal] = useGoal(goalId);

  if (noGoal) { return <GenericNoData noData={noGoal} />; }

  return (
    <Container>
      <GoalPageHeader goal={goal} />
      <GoalUpdateEditForm goalId={goalId} update={newUpdate()} close={() => { props.history.push(`/goals/${goalId}/updates`); }} />
    </Container>
  );
}
