import React from 'react';
import { useAdviser } from '../redux/advisersActions';
import { Col, Container, Row } from 'react-bootstrap';
import * as Goal from '../models/goal';
import KpiChart, { calcDatapointEndAt, caclcPaddedDateRange } from '../components/KpiChart';
import { TokenAdviserPageHeader } from '../components/PageHeaders';
import { TokenAdviserNoData } from '../components/NoData';

function AdviserKpi ({ goalId, kpi, dates, goalStartAt, goalEndAt }) {
  const datapointEndAt = calcDatapointEndAt(goalEndAt);
  const datapoints = Goal.calcKpiDatapoints(kpi, dates, datapointEndAt);

  return (
    <>
      <Row>
        <Col><h5>{kpi.name}</h5></Col>
      </Row>
      <Row>
        <Col xs={12}>
          <KpiChart {...{ kpiName: kpi.name, dates, datapoints }} />
        </Col>
      </Row>
    </>
  );
}

export default function TokenAdviserKpis (props) {
  const adviserId = props.match.params.id;

  const [adviser, noAdviser] = useAdviser(adviserId);
  if (noAdviser) { return <TokenAdviserNoData noData={noAdviser} />; }

  const goal = adviser.goal;
  const dates = caclcPaddedDateRange(goal.startAt, goal.endAt);

  return (
    <Container>
      <TokenAdviserPageHeader adviser={adviser} />
      { goal.kpis && goal.kpis.map((kpi) => (
        <AdviserKpi key={`${goal.goalId}:${kpi.id}`}{...{ goalId: goal.goalId, kpi, dates, goalStartAt: goal.startAt, goalEndAt: goal.endAt }} />
      ))}
    </Container>
  );
}
