import React from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { ErrorMessage, Field, getIn } from 'formik';
import DateRangePicker from './DateRangePicker';
import FieldArrayErrorMesage from './FieldArrayErrorMessage';

export default function GoalEditFields ({ values, errors, touched }) {
  return (
    <>
      <Field name='nickname'>
        {({ field }) => (
          <FormGroup controlId={field.name}>
            <FormLabel>My Nickname <small className='text-muted'>(for advisers)</small></FormLabel>
            <FormControl {...field} isInvalid={!!(getIn(errors, field.name) && getIn(touched, field.name))} type='text' />
            <FormControl.Feedback type='invalid'>
              <ErrorMessage name={field.name} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
      <Field name='title'>
        {({ field }) => (
          <FormGroup controlId={field.name}>
            <FormLabel>Title</FormLabel>
            <FormControl {...field} isInvalid={!!(getIn(errors, field.name) && getIn(touched, field.name))} type='text' />
            <FormControl.Feedback type='invalid'>
              <ErrorMessage name={field.name} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
      <Field name='startRange'>
        {({ field, form }) => (
          <FormGroup controlId='startRange'>
            <FormLabel>Dates</FormLabel>
            <div><DateRangePicker
              invalid={!!((errors.startAt && touched.startAt) || (errors.endAt && touched.endAt))}
              values={{ startAt: values.startAt, endAt: values.endAt }}
              onChange={(startAt, endAt) => {
                form.setFieldValue('startAt', startAt);
                form.setFieldValue('endAt', endAt);
              }}
              onBlur={() => {
                form.setFieldTouched('startAt');
                form.setFieldTouched('endAt');
              }} />
            </div>
            <FormControl.Feedback type='invalid' style={{ display: 'block' }}>
              <ErrorMessage name={'startAt'} />
              <ErrorMessage name={'endAt'} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
      <Field name='kpis[0].name'>
        {({ field }) => (
          <FormGroup controlId='kpis[0].name'>
            <FormLabel>KPI (Key Performance Indicator)</FormLabel>
            <FormControl {...field} type='text'
              isInvalid={!!((getIn(errors, field.name) && getIn(touched, field.name)) ||
                           (errors['kpis'] && touched['kpis']))} />
            <FormControl.Feedback type='invalid'>
              <ErrorMessage name={field.name} />
              <FieldArrayErrorMesage name={'kpis'} errors={errors} />
            </FormControl.Feedback>
          </FormGroup>
        )}
      </Field>
    </>
  );
}
