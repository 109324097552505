export const RESOURCE_TYPE_ADVISER = 'adviser';
export const RESOURCE_TYPE_ADVISER_ACTIVATION = 'adviser_activation';
export const RESOURCE_TYPE_ADVISERSHIP = 'advisership';
export const RESOURCE_TYPE_GOAL = 'goal';

export const API__CREATE = 'API::CREATE';
export const API__CREATE_ERROR = 'API::CREATE_ERROR';
export const API__CREATED = 'API::CREATED';
export const API__DELETE = 'API::DELETE';
export const API__DELETE_ERROR = 'API::DELETE_ERROR';
export const API__DELETED = 'API::DELETED';
export const API__INVALIDATE = 'API::INVALIDATE';
export const API__INVALIDATE_LIST = 'API::INVALIDATE_LIST';
export const API__RECEIVE = 'API::RECEIVE';
export const API__RECEIVE_ERROR = 'API::RECEIVE_ERROR';
export const API__RECEIVE_LIST = 'API::RECEIVE_LIST';
export const API__RECEIVE_LIST_ERROR = 'API::RECEIVE_LIST_ERROR';
export const API__REQUEST = 'API::REQUEST';
export const API__REQUEST_LIST = 'API::REQUEST_LIST';
export const API__UPDATE = 'API::UPDATE';
export const API__UPDATE_ERROR = 'API::UPDATE_ERROR';
export const API__UPDATED = 'API::UPDATED';
