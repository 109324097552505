// import produce from 'immer';
import * as AA from './apiActions';
import * as AR from './apiReducers';
import * as AdviserAction from '../models/adviserActivation';
import * as C from './constants';
import { useResource } from './hooks';

//
// Hooks
//

export function useAdviserActivation (adviserActivationId) {
  return useResource(
    () => fetchIfNeeded(adviserActivationId),
    () => isFetchingSelector(adviserActivationId),
    () => isDeletedSelector(adviserActivationId),
    () => fetchErrorSelector(adviserActivationId),
    () => dataSelector(adviserActivationId)
  );
}

//
// Selectors
//

export function dataSelector (activationSecretId) { return AR.dataSelector(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId); }

export function didInvalidateSelector (activationSecretId) { return AR.didInvalidateSelector(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId); }

export function isFetchingSelector (activationSecretId) { return AR.isFetchingSelector(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId); }
export function fetchErrorSelector (activationSecretId) { return AR.fetchErrorSelector(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId); }

export function isDeletingSelector (activationSecretId) { return AR.isDeletingSelector(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId); }
export function deleteErrorSelector (activationSecretId) { return AR.deleteErrorSelector(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId); }
export function isDeletedSelector (activationSecretId) { return AR.isDeletedSelector(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId); }

//
// Actions
//

export function fetchIfNeeded (activationSecretId) {
  return AA.fetchIfNeeded(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId, () => AdviserAction.get(activationSecretId));
}

export function deleteIfPossible (activationSecretId) {
  return AA.deleteIfPossible(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId, () => AdviserAction.del(activationSecretId));
}

export function invalidate (activationSecretId) {
  return AA.invalidate(C.RESOURCE_TYPE_ADVISER_ACTIVATION, activationSecretId);
}
