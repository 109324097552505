import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FormControl, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import { CancelClickButton, SaveButton } from './FormButtons';
import React from 'react';
import * as Auth from '../libs/auth';
import { reportException } from '../libs/errors';

const confirmationSchema = yup.object().shape({
  confirmationCode: yup.string().default('')
});

export function SignupConfirmationForm ({ email, onConfirmationSuccess, onCancel, setAlert }) {
  async function handleSubmit (values, actions) {
    try {
      await Auth.confirmSignUp(email, values.confirmationCode);
      onConfirmationSuccess();
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e);

      if (e instanceof Auth.CodeMismatchAuthError) {
        setAlert(<>Invalid verification code provided, please try again.</>);
      } else {
        setAlert(e.message);
      }
    }
  }

  async function resendConfirmationCode (e) {
    e.preventDefault();
    try {
      await Auth.resendSignUp(email);
      setAlert(<>Confirmation code sent to {email}.</>);
    } catch (e) {
      reportException(e);
      setAlert(e.message);
    }
  }

  let initialValues = confirmationSchema.default();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={confirmationSchema}>
      {({ submitting, dirty, values, errors, touched }) => (
        <Form>
          <Field name='confirmationCode'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Confirmation Code</FormLabel>
                <FormControl {...field} autoFocus type='tel' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid' style={{ display: 'block' }}>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
                <FormText>Please check your email for the code. <a href='/#' onClick={resendConfirmationCode}>Send a new code</a></FormText>
              </FormGroup>
            )}
          </Field>
          <SaveButton disabled={submitting || !dirty}
            isLoading={submitting}
            text='Verify'
            loadingText='Verifying…' />
          {onCancel && <CancelClickButton onClick={onCancel} />}
        </Form>)
      }
    </Formik>
  );
}
