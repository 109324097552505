import React, { useState } from 'react';
import * as Auth from '../libs/auth';
import { FormGroup, FormControl, FormLabel, Container, Alert } from 'react-bootstrap';
import { SaveButton } from '../components/FormButtons';
import './Login.css';
import { reportException } from '../libs/errors';
import * as yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { SUPPORT_EMAIL } from '../libs/constants';
import { Link } from 'react-router-dom';
import { SignupConfirmationForm } from '../components/SignupConfirmationForm';

const loginSchema = yup.object().shape({
  email: yup.string().email('Must be an email address').required('Email is required').default(''),
  password: yup.string().required('Please Enter your password').default('')
});

function LoginForm ({ handleSubmit }) {
  let initialValues = loginSchema.default();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={loginSchema}>
      {({ submitting, dirty, values, errors, touched }) => (
        <Form>
          <Field name='email'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Email</FormLabel>
                <FormControl {...field} autoFocus type='email' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <Field name='password'>
            {({ field }) => (
              <FormGroup controlId={field.name}>
                <FormLabel>Password</FormLabel>
                <FormControl {...field} type='password' isInvalid={!!(errors[field.name] && touched[field.name])} />
                <FormControl.Feedback type='invalid'>
                  <ErrorMessage name={field.name} />
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Field>
          <SaveButton disabled={submitting || !dirty}
            isLoading={submitting}
            text='Login'
            loadingText='Logging in…' />
        </Form>
      )}
    </Formik>
  );
}

export default function Login (props) {
  const [alert, setAlert] = useState(null);
  const [confirmationEmail, setConfirmationEmail] = useState(null);

  async function handleSubmit (values, actions) {
    try {
      await Auth.signIn(values.email, values.password);

      actions.setSubmitting(false);
      setAlert(null);
      props.userHasAuthenticated(true);
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e);

      if (e instanceof Auth.IncorrectPasswordAuthError) {
        setAlert(<>Incorrect password.  Re-enter or <Link to={'/forgot'}>reset</Link> your password.</>);
      } else if (e instanceof Auth.UserDisabledAuthError) {
        setAlert(<>This user has been disabled.  Please contact {SUPPORT_EMAIL} for more information.</>);
      } else if (e instanceof Auth.UserNotConfirmedAuthError) {
        setAlert(<>Please confirm your email address by entering the code.</>);
        setConfirmationEmail(values.email);
      } else if (e instanceof Auth.UserNotFoundAuthError) {
        setAlert(<>This email is not registered.  Please <Link to={'/signup'}>signup</Link>.</>);
      } else {
        setAlert(e.message);
      }
    }
  }

  async function onConfirmationSuccess () {
    setAlert(<>Email address confirmed!  Please login.</>);
    setConfirmationEmail(null);
  }

  return (
    <Container className='Login'>
      {alert && <Alert variant={'danger'}>{alert}</Alert>}
      { confirmationEmail === null
        ? <LoginForm handleSubmit={handleSubmit} />
        : <SignupConfirmationForm email={confirmationEmail}
          onConfirmationSuccess={onConfirmationSuccess}
          onCancel={(e) => { e.preventDefault(); setConfirmationEmail(null); setAlert(null); }}
          setAlert={setAlert} /> }
    </Container>
  );
}
