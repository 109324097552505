import * as Sentry from '@sentry/browser';

export class DomainError extends Error {
  constructor (message, { cause, tags }) {
    super(message);
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;
    this.cause = cause;
    this.tags = tags;
  }
}

export function initErrorReporter () {
  Sentry.init({
    dsn: 'https://a82844285cd04d48b9d49ae5600db8b0@sentry.io/2411391',
    environment: process.env.REACT_APP_STAGE
  });
}

export function reportException (e) {
  if (e.cause || e.tags) {
    Sentry.withScope(scope => {
      if (e.tags) {
        scope.setTags(e.tags);
      }

      if (e.cause) {
        scope.setExtras(e.cause);
      }

      Sentry.captureException(e);
    });
  } else {
    Sentry.captureException(e);
  }
}
