import * as AR from './apiReducers';
import * as C from './constants';

//
// Interface Actions
//

export function createIfPossible (resourceType, tempResourceId, post) {
  return (dispatch, getState) => {
    if (AR.canCreateSelector(resourceType, tempResourceId)(getState())) {
      dispatch(createResource(resourceType, tempResourceId));
      return post()
        .then(resourceId => dispatch(createdResource(resourceType, tempResourceId, resourceId)))
        .catch(e => { dispatch(createResourceError(resourceType, tempResourceId, e)); throw (e); });
    }
  };
}

export function deleteIfPossible (resourceType, resourceId, del) {
  return (dispatch, getState) => {
    if (AR.canDeleteSelector(resourceType, resourceId)(getState())) {
      dispatch(deleteResource(resourceType, resourceId));
      return del()
        .then(() => { dispatch(deletedResource(resourceType, resourceId)); })
        .catch(e => { dispatch(deleteResourceError(resourceType, resourceId, e)); throw (e); });
    }
  };
}

export function fetchIfNeeded (resourceType, resourceId, get) {
  return (dispatch, getState) => {
    if (AR.shouldFetchSelector(resourceType, resourceId)(getState())) {
      return dispatch(fetchResource(resourceType, resourceId, get));
    }
  };
}

export function invalidate (resourceType, resourceId) {
  return {
    type: C.API__INVALIDATE,
    resourceType,
    resourceId
  };
}

export function invalidateList (resourceType) {
  return {
    type: C.API__INVALIDATE_LIST,
    resourceType
  };
}

export function listIfNeeded (resourceType, list) {
  return (dispatch, getState) => {
    if (AR.shouldListSelector(resourceType)(getState())) {
      return dispatch(listResources(resourceType, list));
    }
  };
}

export function putResource (resourceType, resourceId, put, get) {
  return (dispatch, getState) => {
    if (AR.canUpdateSelector(resourceType, resourceId)(getState())) {
      dispatch(updateResource(resourceType, resourceId));
      return put()
        .then(() => { dispatch(fetchResource(resourceType, resourceId, get)); })
        .then(() => { dispatch(updatedResource(resourceType, resourceId)); })
        .catch(e => { dispatch(updateResourceError(resourceType, resourceId, e)); throw (e); });
    }
  };
}

//
// Internal Actions
//

function createResource (resourceType, tempResourceId) {
  return {
    type: C.API__CREATE,
    resourceType,
    resourceId: tempResourceId
  };
}

function createResourceError (resourceType, tempResourceId, error) {
  return {
    type: C.API__CREATE_ERROR,
    resourceType,
    resourceId: tempResourceId,
    error
  };
}

function createdResource (resourceType, tempResourceId, createdResourceId) {
  return {
    type: C.API__CREATED,
    resourceType,
    resourceId: tempResourceId,
    createdResourceId
  };
}

function deleteResource (resourceType, resourceId) {
  return {
    type: C.API__DELETE,
    resourceType,
    resourceId
  };
}

function deleteResourceError (resourceType, resourceId, error) {
  return {
    type: C.API__DELETE_ERROR,
    resourceType,
    resourceId,
    error
  };
}

function deletedResource (resourceType, resourceId) {
  return {
    type: C.API__DELETED,
    resourceType,
    resourceId
  };
}

function fetchResource (resourceType, resourceId, get) {
  return dispatch => {
    dispatch(requestResource(resourceType, resourceId));
    return get()
      .then(resource => dispatch(receiveResource(resourceType, resourceId, resource)))
      .catch(e => {
        dispatch(receiveResourceError(resourceType, resourceId, e));
      });
  };
}

function listResources (resourceType, list) {
  return dispatch => {
    dispatch(requestResourceList(resourceType));
    return list()
      .then(resourceList => {
        // iterate through the resourceList dispatching individual receive actions and collecting the resource ids
        let resourceIdList = resourceList.map(({ resourceId, resource }) => {
          dispatch(receiveResource(resourceType, resourceId, resource));
          return resourceId;
        });

        return dispatch(receiveResourceList(resourceType, resourceIdList));
      })
      .catch(e => {
        dispatch(receiveResourceListError(resourceType, e));
      });
  };
}

function requestResourceList (resourceType) {
  return {
    type: C.API__REQUEST_LIST,
    resourceType
  };
}

function receiveResourceList (resourceType, resourceIdList) {
  return {
    type: C.API__RECEIVE_LIST,
    resourceType,
    resourceIdList
  };
}

function receiveResourceListError (resourceType, error) {
  return {
    type: C.API__RECEIVE_LIST_ERROR,
    resourceType,
    error
  };
}

function requestResource (resourceType, resourceId) {
  return {
    type: C.API__REQUEST,
    resourceType,
    resourceId
  };
}

function receiveResource (resourceType, resourceId, resource) {
  return {
    type: C.API__RECEIVE,
    resourceType,
    resourceId,
    resource
  };
}

export function receiveResourceError (resourceType, resourceId, error) {
  return {
    type: C.API__RECEIVE_ERROR,
    resourceType,
    resourceId,
    error
  };
}

function updateResource (resourceType, resourceId) {
  return {
    type: C.API__UPDATE,
    resourceType,
    resourceId
  };
}

function updateResourceError (resourceType, resourceId, error) {
  return {
    type: C.API__UPDATE_ERROR,
    resourceType,
    resourceId,
    error
  };
}

function updatedResource (resourceType, resourceId) {
  return {
    type: C.API__UPDATED,
    resourceType,
    resourceId
  };
}
