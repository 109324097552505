import React from 'react';
import { Container } from 'react-bootstrap';
import Kpi from '../components/Kpi';
import { caclcPaddedDateRange } from '../components/KpiChart';
import { useGoal } from '../redux/goalsActions';
import { GoalPageHeader } from '../components/PageHeaders';
import { GenericNoData } from '../components/NoData';

export default function GoalKpis (props) {
  let goalId = props.match.params.id;

  const [goal, noGoal] = useGoal(goalId);

  if (noGoal) { return <GenericNoData noData={noGoal} />; }

  const dates = caclcPaddedDateRange(goal.startAt, goal.endAt);

  return (
    <Container>
      <GoalPageHeader goal={goal} />
      {goal.kpis.map((kpi) => (
        <Kpi key={`${goalId}:${kpi.id}`}{...{ goalId, kpi, dates, goalStartAt: goal.startAt, goalEndAt: goal.endAt }} />
      ))}
    </Container>
  );
}
