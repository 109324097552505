import React from 'react';
import { fromISODatetime } from '../libs/dates';
import * as ReactQuill from 'react-quill';
import { Timeline, TimelineBlip, TimelineEvent } from 'react-event-timeline';
import { COLORS } from '../libs/bootstrapHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function CreateLinkTimelineBlip ({ goalId }) {
  // These styles are a hack to make the timeline "Bubble" clickable by extending the link over it
  const styles = { marginLeft: '-45px', paddingLeft: '45px' };
  return (
    <TimelineBlip className='mt-1'
      title={<Link style={styles} to={`/goals/${goalId}/updates/new`}>Create a new update</Link>}
      style={{ color: COLORS.primary }}
      iconColor={COLORS.primary}
      icon={<Link to={`/goals/${goalId}/updates/new`}><FontAwesomeIcon icon={'plus'} /></Link>}
    />
  );
}

function EditLink ({ goalId, updateId }) {
  return (
    <Link to={`/goals/${goalId}/updates/${updateId}/edit`}>
      <FontAwesomeIcon size='lg' color={COLORS.primary} icon={'edit'} />
    </Link>
  );
}

export default function GoalUpdatesTimeline ({ goalId, updates, readOnly }) {
  let buttons = [];
  if (!readOnly) {
    buttons.push();
  }

  return (
    <Timeline>
      {readOnly ? <></> : <CreateLinkTimelineBlip goalId={goalId} />}
      {updates.map((u) =>
        <TimelineEvent
          key={u.id}
          createdAt={u.createdAt && fromISODatetime(u.createdAt).toDateString()}
          title={u.title}
          iconColor={COLORS.secondary}
          buttons={readOnly ? [] : [<EditLink key={u.id} goalId={goalId} updateId={u.id} />]}
        >
          <ReactQuill modules={{ toolbar: false }} value={u.text} readOnly>
            <div className='border-0' />
          </ReactQuill>
        </TimelineEvent>
      )}
    </Timeline>
  );
}
