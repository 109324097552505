// import produce from 'immer';
import * as AA from './apiActions';
import * as AR from './apiReducers';
import * as Adviser from '../models/adviser';
import * as C from './constants';
import { useResource } from './hooks';

//
// Hooks
//

export function useAdviser (adviserId) {
  return useResource(
    () => fetchIfNeeded(adviserId),
    () => isFetchingSelector(adviserId),
    () => isDeletedSelector(adviserId),
    () => fetchErrorSelector(adviserId),
    () => dataSelector(adviserId)
  );
}

//
// Selectors
//

export function dataSelector (adviserId) { return AR.dataSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function listDataSelector () { return AR.listDataSelector(C.RESOURCE_TYPE_ADVISER); }
export function listAllDataSelector (filter) { return AR.listAllDataSelector(C.RESOURCE_TYPE_ADVISER, filter); }

export function isCreatingSelector (adviserId) { return AR.isCreatingSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function createErrorSelector (adviserId) { return AR.createErrorSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function createdIdSelector (adviserId) { return AR.createdResourceIdSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }

export function isDeletingSelector (adviserId) { return AR.isDeletingSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function deleteErrorSelector (adviserId) { return AR.deleteErrorSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function isDeletedSelector (adviserId) { return AR.isDeletedSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }

export function didInvalidateSelector (adviserId) { return AR.didInvalidateSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function didInvalidateListSelector () { return AR.didInvalidateListSelector(C.RESOURCE_TYPE_ADVISER); }

export function isFetchingSelector (adviserId) { return AR.isFetchingSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function fetchErrorSelector (adviserId) { return AR.fetchErrorSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }

export function isListingSelector () { return AR.isListingSelector(C.RESOURCE_TYPE_ADVISER); }
export function listErrorSelector () { return AR.listErrorSelector(C.RESOURCE_TYPE_ADVISER); }

export function isUpdatingSelector (adviserId) { return AR.isUpdatingSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }
export function updateErrorSelector (adviserId) { return AR.updateErrorSelector(C.RESOURCE_TYPE_ADVISER, adviserId); }

//
// Actions
//

export function createIfPossible (tempAdviserId, activationSecretId, values) {
  return AA.createIfPossible(C.RESOURCE_TYPE_ADVISER, tempAdviserId, () =>
    Adviser.post(activationSecretId, values)
      .then(adviser => adviser.adviserSecretId)
  );
}

export function deleteIfPossible (adviserId) {
  return AA.deleteIfPossible(C.RESOURCE_TYPE_ADVISER, adviserId, () => Adviser.del(adviserId));
}

export function fetchIfNeeded (adviserId) {
  return AA.fetchIfNeeded(C.RESOURCE_TYPE_ADVISER, adviserId, () => Adviser.get(adviserId));
}

export function invalidate (adviserId) {
  return AA.invalidate(C.RESOURCE_TYPE_ADVISER, adviserId);
}

export function invalidateList () {
  return AA.invalidateList(C.RESOURCE_TYPE_ADVISER);
}

export function updateDetails (adviserId, nickname, avatar, email, notificationOptIn) {
  return (dispatch) => {
    return dispatch(AA.putResource(C.RESOURCE_TYPE_ADVISER, adviserId,
      () => Adviser.put(adviserId, nickname, avatar, email, notificationOptIn),
      () => Adviser.get(adviserId)));
  };
}
