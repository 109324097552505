import React from 'react';
import { useGoal } from '../redux/goalsActions';
import { Container } from 'react-bootstrap';
import { GoalPageHeader } from '../components/PageHeaders';
import GoalUpdatesTimeline from '../components/GoalUpdatesTimeline';
import { GenericNoData } from '../components/NoData';

export default function GoalUpdates (props) {
  let goalId = props.match.params.id;

  const [goal, noGoal] = useGoal(goalId);

  if (noGoal) { return <GenericNoData noData={noGoal} />; }

  return (
    <Container>
      <GoalPageHeader goal={goal} />
      <GoalUpdatesTimeline goalId={goalId} updates={goal.updates} />
    </Container>
  );
}
