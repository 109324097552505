import { useFormikContext } from 'formik';
import React, { useRef } from 'react';
import * as ReactQuill from 'react-quill';

export default function RichTextField ({ value, titleName, textName }) {
  const { setFieldValue } = useFormikContext();

  const quillRef = useRef(null);

  return (<ReactQuill ref={quillRef}
    theme='snow'
    value={value}
    onChange={(updatedValue) => {
      let text = quillRef.current && quillRef.current.getEditor().getText(0, 35).split('\n')[0];
      if (text) {
        setFieldValue(textName, updatedValue);
        setFieldValue(titleName, text + '...');
      } else {
        // There is no text, so save an empty string to clean up any empty html tags (i.e. <P></P>)
        setFieldValue(textName, '');
        setFieldValue(titleName, '');
      }
    }}
  />);
}
