import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import ForgotPassword from './containers/ForgotPassword';
import Login from './containers/Login';
import Signup from './containers/Signup';
import NotFound from './containers/NotFound';
import AppliedRoute from './components/AppliedRoute';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import ActivateAdviser from './containers/ActivateAdviser';

import GoalEdit from './containers/GoalEdit';
import GoalInfo from './containers/GoalInfo';
import GoalNew from './containers/GoalNew';
import GoalKpis from './containers/GoalKpis';
import GoalUpdates from './containers/GoalUpdates';
import GoalUpdatesEdit from './containers/GoalUpdatesEdit';
import GoalUpdatesNew from './containers/GoalUpdatesNew';

import GoalAdviserEdit from './containers/GoalAdviserEdit';
import GoalAdviserList from './containers/GoalAdviserList';
import GoalAdviserNew from './containers/GoalAdviserNew';
import GoalAdviserShow from './containers/GoalAdviserShow';

import Privacy from './containers/Privacy';
import Terms from './containers/Terms';

import TokenAdviserInfo from './containers/TokenAdviserInfo';
import TokenAdviserInfoEdit from './containers/TokenAdviserInfoEdit';
import TokenAdviserKpis from './containers/TokenAdviserKpis';
import TokenAdviserUpdates from './containers/TokenAdviserUpdates';

function GoalsRedirect (props) { return (<Redirect to={`/goals/${props.match.params.id}/kpis`} />); }
function TokenAdviserRedirect (props) { return (<Redirect to={`/advisers/${props.match.params.id}/updates`} />); }

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path='/' exact component={Home} props={childProps} />
    <AppliedRoute path='/adviser_activations/:id' exact component={ActivateAdviser} props={childProps} />
    <AppliedRoute path='/advisers/:id' exact component={TokenAdviserRedirect} props={childProps} />
    <AppliedRoute path='/advisers/:id/kpis' exact component={TokenAdviserKpis} props={childProps} />
    <AppliedRoute path='/advisers/:id/updates' exact component={TokenAdviserUpdates} props={childProps} />
    <AppliedRoute path='/advisers/:id/info' exact component={TokenAdviserInfo} props={childProps} />
    <AppliedRoute path='/advisers/:id/info/edit' exact component={TokenAdviserInfoEdit} props={childProps} />
    <AppliedRoute path='/privacy' exact component={Privacy} props={childProps} />
    <AppliedRoute path='/terms' exact component={Terms} props={childProps} />

    <UnauthenticatedRoute path='/login' exact component={Login} props={childProps} />
    <UnauthenticatedRoute path='/signup' exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path='/forgot' exact component={ForgotPassword} props={childProps} />

    <AuthenticatedRoute path='/goals/:id/advisers' exact component={GoalAdviserList} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/advisers/new' exact component={GoalAdviserNew} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/advisers/:advisershipId' exact component={GoalAdviserShow} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/advisers/:advisershipId/edit' exact component={GoalAdviserEdit} props={childProps} />
    <AuthenticatedRoute path='/goals/new' exact component={GoalNew} props={childProps} />
    <AuthenticatedRoute path='/goals/:id' exact component={GoalsRedirect} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/info' exact component={GoalInfo} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/info/edit' exact component={GoalEdit} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/kpis' exact component={GoalKpis} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/updates' exact component={GoalUpdates} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/updates/new' exact component={GoalUpdatesNew} props={childProps} />
    <AuthenticatedRoute path='/goals/:id/updates/:updateId/edit' exact component={GoalUpdatesEdit} props={childProps} />

    <AuthenticatedRoute path='/goals/:id/edit' exact component={GoalEdit} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
