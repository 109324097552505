import React from 'react';
import { FormControl, Form, InputGroup } from 'react-bootstrap';
import './KpiUpdateForm.css';
import { fromISODate } from '../libs/dates';
import { SaveButton } from './FormButtons';
import { Formik, Form as FormK, ErrorMessage, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as GA from '../redux/goalsActions';
import * as yup from 'yup';
import { reportException } from '../libs/errors';
import ErrorAlert from './ErrorAlert';

const datapointSchema = yup.object().shape({
  kpiValue: yup.number().typeError('must be a number')
});

export default function KpiUpdateForm ({ goalId, kpiName, index, onIndexChange, dates, datapoints }) {
  const dispatch = useDispatch();
  const isUpdating = useSelector(GA.isUpdatingSelector(goalId));
  const updateError = useSelector(GA.updateErrorSelector(goalId));

  function handleSubmit (values, actions) {
    dispatch(GA.updateKpiDatapoints(goalId, kpiName, [dates[values.kpiIndex]], [parseInt(values.kpiValue)])).then(() => {
      actions.setSubmitting(false);
    }).catch((e) => {
      reportException(e);
      // ignore
    });
  }

  return (
    <>
      <ErrorAlert error={updateError} />
      <Formik
        initialValues={{ kpiValue: datapoints[index], kpiIndex: index }}
        onSubmit={handleSubmit}
        enableReinitialize

        validationSchema={datapointSchema}>
        {({ submitting, dirty, errors, touched }) => (
          <FormK>
            <InputGroup className='mb-3'>
              <InputGroup.Prepend>
                <Form.Control className='kpi-update-select' as='select' value={index}
                  onChange={(e) => { onIndexChange(parseInt(e.target.value)); }}>
                  {
                    dates.map((d, i) => (d && <option key={d} value={i}>{fromISODate(d).toDateString()}</option>))
                  }
                </Form.Control>
              </InputGroup.Prepend>
              <Field name='kpiValue'>
                {({ field }) => (
                  <>
                    <FormControl {...field}
                      onFocus={(e) => e.target.select()}
                      pattern='[0-9]*'
                      isInvalid={!!touched[field.name] && !!errors[field.name]} type='text' />
                    <FormControl.Feedback type='invalid'>
                      <ErrorMessage name={field.name} />
                    </FormControl.Feedback>
                  </>
                )}
              </Field>
              <InputGroup.Append>
                <SaveButton disabled={submitting || !dirty || isUpdating} isLoading={submitting || isUpdating} />
              </InputGroup.Append>
            </InputGroup>

          </FormK>)
        }
      </Formik>
    </>);
}
